import React from 'react';

const CalendlyEmbed = () => {
  return (
    <div className="mx-auto p-4">
      <div className="relative w-full" style={{ paddingTop: "125%" }}>
        <iframe
          src="https://calendly.com/partnerships-cloudevolve/30min"
          className="absolute top-0 left-0 w-full h-full"
          frameBorder="0"
          title="Scheduling Appointment"
          allowFullScreen
        />
      </div>
    </div>

  );
};

export default CalendlyEmbed;