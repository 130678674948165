import React from "react";
// import pricingImage from "../assets/pricing.webp";
import cloudImage from "../assets/Base Illustration1.webp";
// import rightsidearrow from "../assets/warehouse/rightsidearrow.webp";
import ourjourneyImage from "../assets/Ourjourney.webp";
// import ourteam from "../assets/Rectangle 153.webp";
// import Linkedin from "../assets/linkedin.svg";
import nosaving from "../assets/warehouse/nosaving.webp";
import upto from "../assets/warehouse/upto.webp";
import cloudany from "../assets/warehouse/cloudany.webp";
import cloudev from "../assets/warehouse/cloudev.webp";
import Quotation from "../assets/quotation.webp";

const cardData = [
  {
    title: "United in purpose",
    description:
      "Together, we tackle challenge and celebrate successes - after all, teamwork is our secret sauce!",
    icon: nosaving,
    alt: "Discount Icon",
  },
  {
    title: "Adapt and evolve",
    description:
      "Change is our constant friend! We embrace the unexpected and pivot like pros, turning challenges into opportunities.",
    icon: upto,
    alt: "No Commitment Icon",
  },
  {
    title: "Learn and grow",
    description:
      "Every day is a chance to learn something new and level up our creation!",
    icon: cloudany,
    alt: "Cost Transparency Icon",
  },
  {
    title: "Cheers for peers",
    description:
      "Celebrating wins, big or small, is our motto! Because together, we can conquer it all!",
    icon: cloudev,
    alt: "Personalized Savings Icon",
  },
];

// const teamMembers = [
//   { id:1,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   { id:2,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   { id:3,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   { id:4,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   { id:5,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   {id:6, name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   {id:7, name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
//   { id:8,name: 'Jansen Mustafa', role: 'CEO and Founder', image: 'https://via.placeholder.com/150' },
// ];
// const getMarginTop = (id) => {
//   switch (id) {
//     case 2:
//       return "10%";
//     case 3:
//       return "20%";
//     case 5:
//       return "13%";
//     default:
//       return "0px";
//   }
// };

// const CareersList = [
//   {
//     id: 1,
//     name: "Sai ",
//     position: "Frontend Developer",
//     link_url: "https://dataevolve.in/",
//     img: Linkedin,
//     image: Linkedin,
//   },
//   {
//     id: 2,
//     name: "Satish Dandabathula",
//     position: "Designer",
//     link_url:
//       "https://www.linkedin.com/in/satish-dandabathula-00b35115b/?originalSubdomain=in",
//     img: Linkedin,
//     image: Linkedin,
//   },
//   {
//     id: 3,
//     name: "Raj",
//     position: "Backend Developer",
//     link_url: "https://www.linkedin.com/in/mohan-lanke-544b4b214/",
//     img: Linkedin,
//     image: Linkedin,
//   },
//   {
//     id: 4,
//     name: "Pawan",
//     position: "Politician",
//     link_url: "https://www.linkedin.com/company/adp/",
//     img: Linkedin,
//     image: Linkedin,
//   },
//   {
//     id: 5,
//     name: "Ram",
//     position: "Global Star",
//     link_url: "https://www.linkedin.com/company/adp/",
//     img: Linkedin,
//     image: Linkedin,
//   },
// ];

const Aboutus = () => {
  return (
    <div className="text-gray-800">
      <div className="flex justify-center items-center ">
        <div
          className="flex justify-center items-center text-black mt-5 py-2 px-3 rounded-lg font-semibold border border-black w-fit"
        >
          <span className="jakarta text-sm">About us</span>
        </div>
      </div>
      {/* Header Section */}
      <section className="text-center py-6">
        <h1 className="rubik text-4xl font-bold leading-[48px] text-center text-[#141414]">
          We believe in delivering better, smarter, and <br></br>faster solution
          with savings
        </h1>
        {/* <img src={Quotation} alt="" width={15} />
        <p className="mt-2 text-lg jakarta text-[#141414]">          
          The skyrocketing costs of AWS inspired us to come up with a solution ~
          CloudEvolve
        </p> */}

        <div>
          <span
            style={{
              position: "absolute",
              marginLeft: "-12px",
              marginTop: "-5px",
            }}
          >
            <img src={Quotation} alt="" width={30} />
          </span>
          <span style={{ position: "relative" }}>
            The skyrocketing costs of AWS inspired us to come up with a solution
            ~ CloudEvolve
          </span>
        </div>

        <div className="mt-8">
          <img src={cloudImage} alt="Cloud" className="w-full h-auto" />
        </div>
      </section>

      {/* Features Section */}
      <section className="text-center bg-white">
        <h2 className=" text-2xl font-bold rubik text-[#141414]">
          Our journey
        </h2>
        <p className="mt-2 text-lg italic jakarta text-[#141414]">
          At CloudEvolve, our journey began with a vision to simplify cloud cost
          management for everyone. <br></br>Founded by a team of cloud
          enthusiasts, we saw the challenges business faced in navigating{" "}
          <br></br>complex pricing structures and unpredictable costs.
        </p>

        <div className="mt-14">
          <img
            src={ourjourneyImage}
            alt="Pricing"
            className="w-full max-w-screen-md mx-auto h-auto"
          />
        </div>
      </section>

      <div className="items-center justify-center space-y-12">
        {/* First Section */}
        <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-8">
          <div className="mr-[54%] sm:mr-0 w-32 h-20 bg-white-200 border-4 border-yellow-400 rounded-lg mt-16"></div>
          <div>
            <p className="text-[#141414] text-sm max-w-lg jakarta mt-4 sm:mt-16 ml-[4%]">
              Driven by our passion for innovation, we developed a platform that
              not only provides exclusive discounts but also offers real-time
              insights to help you make informed decisions.
            </p>
          </div>
        </div>

        <style jsx>{`
          @media (min-width: 320px) and (max-width: 425px) {
            .txt {
              right: 32%;
              position: relative;
            }
              .txtA{
              text-align:center;
              }
          }
        `}</style>

        {/* Second Section */}
        <div className="flex flex-col-reverse sm:flex-row items-center justify-center space-x-8">
          <p className="text-[#141414] text-sm max-w-lg text-left jakarta ml-[4%]">
            Along the way, we’ve built a community focused on collaboration,
            transparency, and shared success, empowering our users to unlock
            their cloud potential effortlessly.
          </p>
          <div className="w-32 h-20 bg-white-200 border-4 border-yellow-400 rounded-lg txt"></div>
        </div>

        {/* Box-shadowed Horizontal Line */}
        <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[50px] md:mt-[50px] lg:mt-[100px]"></div>

        <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-8">
          <div className="sm:mr-0 w-32 h-28 bg-white-200 border-4 border-yellow-400 rounded-lg mt-10"></div>
          <div>
            <p className="text-[#141414] text-sm max-w-lg jakarta mt-4 sm:mt-16 ml-[4%]">
              <h2 className="text-2xl font-bold mb-3 rubik text-[#141414] txtA">
                Our promise
              </h2>
              We’ve got your back. From exclusive discounts to real-time cloud
              intelligence, we promise to deliver savings without the guesswork.
              With us, it’s always no risk, no commitment, and no hidden
              surprise - just savings.
            </p>
          </div>
        </div>

        {/* <div className="flex items-reverse justify-center space-x-8 ">
          <div className="w-32 min-h-[120px] bg-white-200 border-4 border-yellow-400 rounded-lg"></div>
          <div className="flex flex-col">
            <h2 className="text-2xl font-bold mb-3 rubik text-[#141414]">
              Our promise
            </h2>
            <p className="text-[#141414] text-sm max-w-lg jakarta">
              We’ve got your back. From exclusive discounts to real-time cloud
              intelligence, we promise to deliver savings without the guesswork.
              With us, it’s always no risk, no commitment, and no hidden
              surprise - just savings.
            </p>
          </div>
        </div> */}
      </div>

      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[50px] md:mt-[70px] lg:mt-[100px] lg:mb-[100px]"></div>
      {/* <div className="mt-8 mb-5">
        <div className="max-w-6xl mx-auto py-10 px-4 text-center">
          <h2 className="rubik text-4xl font-bold leading-[48px] text-center text-[#141414]">
            Our team
          </h2>
          <h3
            className="font-semibold mt-2 jakarta text-[#141414]"
          // style={{ color: "#222222" }}
          >
            Meet the dream team!
          </h3>
          <br />
          <div className="flex justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 lg:gap-32">
              {CareersList.map((member, index) => (
                <div
                  key={index}
                  className="w-full max-w-[13rem] bg-white rounded-2xl"
                  style={{ marginTop: getMarginTop(member.id) }}
                >
                  <img
                    src={ourteam}
                    alt="ourteam"
                    className="rounded-2xl object-cover"
                    style={{ width: "100%", height: "13rem" }} // Decrease width and increase height
                  />
                  <div
                    style={{ textAlign: "justify", marginTop: "10px" }}
                    className="text-[#141414]"
                  >
                    <h3
                      className="jakarta text-[#141414]"
                      style={{ marginBottom: "0px", fontSize: "1rem" }}
                    >
                      {member.name}
                      <span
                        style={{ float: "right", marginTop: "-10px" }}
                        className="text-[#141414]"
                      >
                        <a
                          href={member.link_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={member.image}
                            alt="LinkedIn"
                            style={{
                              width: "30px",
                              marginTop: "10px",
                            }}
                          />
                        </a>
                      </span>
                    </h3>
                    <p
                      className="jakarta text-[#141414]"
                      style={{ marginTop: "0px" }}
                    >
                      {member.position}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* Box-shadowed Horizontal Line */}
      {/* <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[50px] md:mt-[70px] lg:mt-[100px]"></div> */}

      <div
        className="text-center mt-5 pb-5 pt-[10px]" // Add padding-top to the div
      >
        <h3
          className="rubik text-4xl font-bold leading-[48px] text-center text-[#141414]"
        // style={{ color: "#000000" }}
        >
          Our spirit
        </h3>

        <h3 className="font-normal mt-2 jakarta text-[#141414]">
          People are loving CloudEvolve
        </h3>

        <div className="relative flex flex-col justify-center items-center">
          {/* Horizontal Line */}
          <div className="absolute lg:top-60 md:top-60 lg:left-0 md:left-0 lg:w-[768px]  lg:ml-32 xl:ml-[300px] md:w-[700px] md:ml-6 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4"></div>

          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-8 lg:h-full md:h-full lg:w-0.5 md:w-0.5 bg-gray-25 transform -translate-x-1/2"></div>

          <div className="flex flex-wrap justify-center gap-7 mt-12 px-4 lg:px-32 md:px-10 xl:px-80 ">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center w-72 "
                style={{ flexBasis: "calc(50% - 16px)" }}
              >
                <div className="h-16 w-16 flex items-center justify-center rounded">
                  <img src={card.icon} alt={card.alt} className="w-14 h-14" />
                </div>
                <h4 className="text-lg font-bold mt-4 jakarta text-[#141414]">
                  {card.title}
                </h4>
                <p
                  className="text-sm  mt-2 jakarta text-[#141414]"
                  style={{ width: "320px" }}
                >
                  {card.description}
                </p>
              </div>
            ))}
          </div>
          <div
            className="absolute left-0 right-0 h-4"
            style={{

              top: "calc(100% + 100px)",
            }}
          ></div>

        </div>
      </div>

    </div>
  );
};

export default Aboutus;