import React from 'react';
// import Logo360 from '../assets/logo360.webp';
// import Vector625 from '../assets/Vector 625.webp';
import Frameblue from '../assets/Frameblue.webp';
import Checkbadge from '../assets/check-badge.webp';
import Frameyellow from '../assets/Frameyellow.webp';
import Frameorange from '../assets/Frameorange.webp';
import Framepink from '../assets/Framepink.webp';
import Frame1 from '../assets/Frame1.webp';
import Frame2 from '../assets/Frame2.webp';
import Frame3 from '../assets/Frame3.webp';
import Frame4 from '../assets/Frame4.webp';
import { useNavigate } from "react-router-dom";
import Icon1 from '../assets/Icon1.webp';
import Group1 from '../assets/Group1.webp';
// import Hand from "../assets/hand.webp";
import Arrow from '../assets/rightsidearrow.webp';
import Cloud from '../assets/cloud.webp';
import checkCircleIcon from "../assets/Icon.webp";
import Group2 from "../assets/group2.webp";
import Hero from "../assets/Hero.mp4";
import Hand from "../assets/get started.gif";



const Cloud360 = () => {
  const navigate = useNavigate();



  const cardData = [
    {
      title: "Cost Efficiency",
      description: "Optimize resource usage to ensure \nevery dollar counts.",
      icon: Frame1,
      alt: "Discount Icon",
    },
    {
      title: "Enhanced Security Posture",
      description: "Protect your cloud with proactive threat detection \nand continuous compliance.",
      icon: Frame2,
      alt: "No Commitment Icon",
    },
    {
      title: "Complete Visibility",
      description: "Gain a comprehensive view of your infrastructure for effective tracking and management.",
      icon: Frame3,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Performance Optimization",
      description: "Receive actionable insights to right-size resources and reduce unnecessary costs.",
      icon: Frame4,
      alt: "Personalized Savings Icon",
    },
  ];

  return (
    <div className="bg-white  min-h-screen flex flex-col items-center text-center">

      <div className="flex justify-center items-center ">
        <div
          className="flex justify-center items-center text-black mt-5 py-2 px-3 rounded-lg font-semibold border border-black w-fit"
        >
          <span className="jakarta text-sm">Platform</span>
        </div>
      </div>
      <main className="flex flex-col items-center justify-center mt-6">
        <h1 className="rubik text-4xl leading-[48px] text-center sm:text-5xl font-bold">Cloud 360°</h1>
        <p className="mt-2 jakarta text-lg">
          Get an overall insight of your workloads
        </p>
        <button
          className="mt-6 text-white jakarta px-3 py-3 rounded-lg  border border-black hover:bg-blue-700"
          style={{ backgroundColor: '#02AAE2', border: '1px solid black' }}
          onClick={() => navigate("/contactus")}
        >
          <div className="flex jakarta font-semibold">
            <div>Get in touch</div>
            <div className="ml-2 mt-[2px]">
              <img src={Arrow} alt="icon" />
            </div>
          </div>
        </button>

        {/* Video for both mobile and larger screens */}
        <div className="relative mt-0 w-full">
          <video
            src={Hero}
            autoPlay
            loop
            muted
            className="w-full"
          />
        </div>
      </main>

      <div className="mt-14 ml-0 mobile-m:mr-36 mobile-l:mr-48 h-48 w-48 mr-20 lg:hidden md:hidden">
        <img src={Frameblue} alt="Frame Blue" />
      </div>
      <div className=' flex m-5'>
        <div className=" items-center justify-center  ">
          <div className="text-left text-black jakarta md:text-sm lg:text-lg ">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-6 md:mt-0 lg:mt-0 mb-[20px]">
              Spend Insight
            </h2>
            <p className="text-black">
              <span className="hidden lg:inline">
                Provides a complete view of cloud infrastructure,<br />
                including resource utilization, performance metrics, and<br />
                potential issues across the AWS cloud environment.
              </span>
              <span className="inline lg:hidden">
                Provides a complete view of cloud infrastructure, including resource utilization, performance metrics, and potential issues across the AWS cloud environment.
              </span>
            </p>

          </div>


          <div className="flex items-center mt-12 lg:mt-7 md:mt-7">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className="text-base rubik  md:text-base  lg:text-xl font-bold text-left text-black">
              Real-time monitoring & alerts
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className="text-base font-rubik md:text-base  lg:text-xl font-bold text-left text-black">
              Smarter resource allocation
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className="text-base rubik md:text-base  lg:text-xl font-bold text-left text-black">
              Performance optimization
            </h2>
          </div>
        </div>
        <div className="  ml-24 h-10 lg:h-80 lg:w-80 md:h-72 md:w-[500px] lg:flex md:flex hidden"><img src={Frameblue} alt='frameblue'></img></div>
      </div>



      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[30px] md:mt-[50px] md:mb-[50px] lg:mt-[100px] lg:mb-[100px] "></div>
      <div className='flex flex-col md:flex-row lg:flex-row m-5'>
        <div className=" mb-1 mt-7 sm-max:h-48 sm-max:w-48  ml-18 h-10 mr-12 lg:h-80 lg:w-80 md:h-72 md:w-[550px] lg:flex md:flex ">
          <img src={Frameyellow} alt='frameblue'></img>
        </div>
        <div className=" items-center justify-center  mt-10 md:mt-10 lg:mt-10 ">
          <div className="text-black jakarta text-left md:text-sm lg:text-xl lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-0 mb-[20px]">
              Cloud Patrol
            </h2>
            <p>
              <span className="hidden lg:inline">
                Implements a unified security posture across all cloud<br />
                services, including identity management, data<br />
                protection, threat detection, and compliance with<br />
                industry standards.
              </span>
              <span className="inline lg:hidden">
                Implements a unified security posture across all cloud services, including identity management, data protection, threat detection, and compliance with industry standards.
              </span>
            </p>
          </div>

          <div className="flex items-center mt-12 md:mt-7">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className="text-base rubik  md:text-base  lg:text-xl font-bold text-left text-black">
              Centralized threat detection
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className=" rubik text-base md:text-base lg:text-xl font-bold text-left text-black">
              Compliance with industry regulations
            </h2>
          </div>
          <div className="flex items-center mt-8 md:mt-3">
            <img src={Checkbadge} alt="Check badge" className="mr-4" />
            <h2 className="rubik text-base md:text-base lg:text-xl font-bold text-left text-black">
              24/7 monitoring and incident response
            </h2>
          </div>
        </div>
      </div>



      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[30px] md:mt-[50px] md:mb-[50px] lg:mt-[100px] lg:mb-[100px]"></div>
      <div className="mt-10 mb-6 ml-0 h-48 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden">
        <img src={Frameorange} alt="Frame Blue" />
      </div>
      <div className=' flex m-5'>
        <div className=" items-center justify-center ">
          <div className="text-black jakarta text-left md:text-xs lg:text-xl lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black md:mt-32 mb-[20px]">
              Tag Orbit
            </h2>
            <p>
              <span className="hidden lg:inline">
                Our real-time tagging solution empowers you to<br />
                categorize, manage, and analyse your cloud resources<br />
                effortlessly.
              </span>
              <span className="inline lg:hidden">
                Our real-time tagging solution empowers you to categorize, manage, and analyse your cloud resources effortlessly.
              </span>
            </p>
          </div>

        </div>
        <div className=" mt-20 md:mt-14 lg:mt-0 ml-24 h-10 lg:h-80 lg:w-80 md:h-72 md:w-[400px] lg:flex md:flex hidden"><img src={Frameorange} alt='frameorange'></img></div>

      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-16 md:gap-24 lg:gap-16 max-w-5xl mx-auto  ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4  md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Custom Reports</span>
            <span className="inline md:hidden">Custom Reports</span>
          </h3>
          <p className="text-black-400 jakarta ">
            <span className="hidden md:inline">
              Generate tailored reports to<br></br> visualize resource usage and<br></br> identify optimization<br></br> opportunities.
            </span>
            <span className="inline md:hidden ">
              Generate tailored reports to visualize resource usage and identify optimization opportunities.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            Enhanced Cost Management
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Easily track costs associated with<br></br> specific projects or departments.
            </span>
            <span className="inline md:hidden">
              Easily track costs associated with specific projects or departments.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Improved Governance</span>
            <span className="inline md:hidden">Improved Governance</span>
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Maintain compliance and<br></br> streamline resource audits with<br></br> clear tagging strategies.
            </span>
            <span className="inline md:hidden">
              Maintain compliance and streamline resource audits with clear tagging strategies.
            </span>
          </p>
        </div>
      </div>








      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[40px] md:mt-[50px] md:mb-[50px] lg:mt-[100px] lg:mb-[100px]"></div>
      <div className='flex flex-col md:flex-row lg:flex-row m-5'>
        <div className=" mb-1 mt-6 md:mt-0 sm-max:h-48 sm-max:w-48 lg:mt-0  ml-18 h-10 mr-12 lg:h-80 lg:w-80 md:h-72 md:w-64 lg:flex md:flex ">
          <img src={Framepink} alt='framepink'></img>
        </div>
        <div className=" items-center justify-center  mt-10 md:mt-0 lg:mt-0 ">
          <div className="text-black jakarta ml-0 text-left md:text-sm lg:text-xl lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black mt-0 md:mt-10 lg:mt-10 mb-[20px]">
              <span className="hidden md:inline">Cost Fixer</span>
              <span className="inline md:hidden">Cost Fixer</span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Our Cost Fixer provide intelligent
                analytics to identify<br></br> underutilized resources, enabling
                you to adjust capacities<br /> and reduce unnecessary costs.
                Whether it’s scaling down <br />an oversized instance or
                boosting underperforming ones,<br /> we've got you covered.
              </span>
              <span className="inline md:hidden">
                Our Right-Sizing Recommendations provide intelligent analytics to identify underutilized resources, enabling you to adjust capacities and reduce unnecessary costs. Whether it’s scaling down an oversized instance or boosting underperforming ones, we've got you covered.
              </span>
            </p>
          </div>

        </div>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-16 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Cost Efficiency</span>
            <span className="inline md:hidden">Cost Efficiency</span>
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Automatically identify over-<br></br>provisioned resources and reduce<br></br> unnecessary expenses.
            </span>
            <span className="inline md:hidden">
              Automatically identify over-provisioned resources and reduce unnecessary expenses.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Performance Boost</span>
            <span className="inline md:hidden">Performance Boost</span>
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Ensure that workloads run<br></br> smoothly with the right-sized <br></br>resources.
            </span>
            <span className="inline md:hidden">
              Ensure that workloads run smoothly with the right-sized resources.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Custom Fit</span>
            <span className="inline md:hidden">Custom Fit</span>
          </h3>
          <p className="text-left md:text-center lg:text-center jakarta">
            <span className="hidden md:inline">
              Recommendations based on your<br />
              business needs and actual usage<br />
              trends.
            </span>
            <span className="inline md:hidden">
              Recommendations based on your business needs and actual usage trends.
            </span>
          </p>
        </div>
      </div>





      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[70px] md:mt-[50px] md:mb-[50px] lg:mt-[100px] lg:mb-[100px]"></div>
      <div className="mt-16 ml-0 h-48 w-48 mr-20 mobile-m:mr-36 mobile-l:mr-48 lg:hidden md:hidden">
        <img src={Frameblue} alt="Frame Blue" />
      </div>
      <div className=' flex m-5'>
        <div className=" items-center justify-center " >
          <div className="text-black jakarta text-left md:text-sm lg:text-xl lg:text-left md:text-left">
            <h2 className="text-2xl md:text-4xl lg:text-4xl rubik font-bold text-black md:mt-16 lg:mt-16 mb-[20px]">
              <span className="hidden md:inline">Stop Idle Resources</span>
              <span className="inline md:hidden">Stop Idle Resources</span>
            </h2>
            <p>
              <span className="hidden md:inline">
                Idle resources are silent budget killers. You can identify<br />
                and eliminate unused or underutilized resources before<br />
                they add up to unexpected costs. Don’t let dormant<br />
                resources drain your cloud budget.
              </span>
              <span className="inline md:hidden">
                Idle resources are silent budget killers. You can identify and eliminate unused or underutilized resources before they add up to unexpected costs. Don’t let dormant resources drain your cloud budget.
              </span>
            </p>
          </div>

        </div>
        <div className=" mt-48 md:mt-0 lg:mt-0 ml-24 md:ml-20 h-10 lg:h-80 lg:w-80 md:h-72 md:w-64 lg:flex md:flex hidden"><img src={Frameblue} alt='frameblue'></img></div>

      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-10 mt-16 md:gap-24 lg:gap-16 max-w-5xl mx-auto ">
        {/* Feature 1 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Automated Alerts</span>
            <span className="inline md:hidden">Automated Alerts</span>
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Receive real-time notifications for<br></br> unused resources so you can act<br></br> instantly.
            </span>
            <span className="inline md:hidden">
              Receive real-time notifications for unused resources so you can act instantly.
            </span>
          </p>
        </div>
        {/* Feature 2 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Immediate Savings</span>
            <span className="inline md:hidden">Immediate Savings</span>
          </h3>
          <p className="text-black-400 jakarta">
            <span className="hidden md:inline">
              Cut out waste by shutting down<br></br> idle resources.
            </span>
            <span className="inline md:hidden">
              Cut out waste by shutting down idle resources.
            </span>
          </p>
        </div>
        {/* Feature 3 */}
        <div className="text-left md:text-center lg:text-center ml-4">
          <img
            src={checkCircleIcon}
            alt="Check Icon"
            className="h-8 w-8 mb-4 md:mx-auto lg:mx-auto"
          />
          <h3 className="rubik font-bold text-lg mb-2">
            <span className="hidden md:inline">Ongoing Optimization</span>
            <span className="inline md:hidden">Ongoing Optimization</span>
          </h3>
          <p className="text-left md:text-center lg:text-center jakarta">
            <span className="hidden md:inline">
              Regular scans to ensure your<br></br> cloud environment remains lean<br></br> and cost-effective.
            </span>
            <span className="inline md:hidden">
              Regular scans to ensure your cloud environment remains lean and cost-effective.
            </span>
          </p>
        </div>
      </div>


      {/* Box-shadowed Horizontal Line */}
      <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[50px] md:mt-[100px] lg:mt-[100px]"></div>
      <div className="text-center mt-[50px] md:mt-[100px] lg:mt-[100px] mb-[20px] pb-5" >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Benefits of Cloud 360°
        </h3>
        < h3 className="font-normal mt-2 jakarta" style={{ color: "#222222" }}>
          At Cloudevolve, your security is our top priority. Our advanced tools offer:
        </h3>

        < div className="relative flex flex-col justify-center items-center" >
          {/* Horizontal Line */}
          < div className="absolute lg:top-56 lg:ml-36 md:top-56 md:ml-0 lg:left-0 md:left-0 lg:w-[740px] md:w-[750px] xl:ml-36 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4" > </div>
          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-5 lg:h-[410px] md:h-[420px] w-0.5 bg-gray-25" > </div>
          < div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-40 gap-y-8 mt-12 px-4 lg:px-40" >
            {
              cardData.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center w-full"
                >
                  <div className="h-16 w-16 flex items-center justify-center rounded" >
                    <img src={card.icon} alt={card.alt} className="w-14 h-14" />
                  </div>
                  < h4 className="text-lg font-bold mt-4 font-rubik" > {card.title} </h4>
                  < p
                    className="text-sm text-gray-600 jakarta mt-2"
                    style={{ color: "#222222", maxWidth: '280px' }}
                  >
                    {card.description}
                  </p>
                </div>
              ))}
          </div>
        </div>


      </div>





      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          className="block sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />

        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[65px] lg:mb-[60px] lg:w-52 lg:h-52 xl:mb-[65px] xl:h-72 xl:w-[350px] xl:mr-0 w-24 h-24 md:w-40 md:h-40 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44 h-24 md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2 bg-black rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold font-rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-20 md:ml-0 xl:text-4xl lg:text-4xl md:text-3xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you commitment phobic?
          </h3>
          <p className="font-normal jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-20 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-xl text-white mb-4 text-center sm:text-left">
            Join our community for a situationship!
          </p>
          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-20 xl:ml-20">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black jakarta text-start text-xs font-semibold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              />
            </button>
          </div>
        </div>
      </div>










    </div>
  );
};

export default Cloud360;
