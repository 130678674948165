import React, { useEffect, useRef, useState } from "react";

// import pricingImage from "../assets/pricing.webp";
// import cloudImage from "../assets/Base Illustration1.webp";
import checkCircleIcon from "../assets/Icon.webp";
import rightsidearrow from "../assets/warehouse/rightsidearrow.webp";
// import contractplan from "../assets/Contract plan.webp";
// import pricingcurve from "../assets/pricingcurve.webp";
// import circle from "../assets/circle.webp";
// import price from "../assets/homeimg/price.webp";
// import prc2 from "../assets/homeimg/prc2.webp";
import { useNavigate } from "react-router-dom";
// import Firstyear from "../assets/Firstyear.webp";
// import secondyear from "../assets/Secondyear.webp";
// import Thirdyear from "../assets/Thirdyear.webp";
import First from "../assets/first.mp4";
import Second from "../assets/second.mp4";
import Third from "../assets/third.mp4";
import Hero from "../assets/price.mp4";

const PricingPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollPositionSecond, setScrollPositionSecond] = useState(0); // New state for the second circle
  const pathRef = useRef(null);
  const pathRefSecond = useRef(null); // New ref for second path
  const sectionRef = useRef(null);
  const navigate = useNavigate();

  const handleScroll = () => {
    if (sectionRef.current) {
      const sectionTop = sectionRef.current.offsetTop;
      const sectionHeight = sectionRef.current.clientHeight;

      // Calculate scroll position relative to the section
      const scrollWithinSection = window.scrollY - sectionTop;

      // Set scroll position based on the section's height, limit it to the section range
      setScrollPosition(
        Math.min(Math.max(scrollWithinSection, 0), sectionHeight)
      );

      // Add similar logic for the second SVG circle
      const secondPathTop = sectionRef.current.offsetTop + sectionHeight;
      const scrollWithinSecondSection = window.scrollY - secondPathTop;
      setScrollPositionSecond(
        Math.min(Math.max(scrollWithinSecondSection, 0), sectionHeight)
      );
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    if (pathRef.current) {
      const totalPathLength = pathRef.current.getTotalLength();

      // Calculate the scroll factor to map the scroll position to path length
      const scrollFactor = totalPathLength / sectionRef.current.clientHeight;

      // Map the scroll position to a scroll offset along the path
      const scrollOffset = scrollPosition * scrollFactor;

      // Update the circle position along the path
      const circle = document.getElementById("scrollCircle");
      if (circle) {
        const point = pathRef.current.getPointAtLength(scrollOffset);
        circle.setAttribute("cx", point.x);
        circle.setAttribute("cy", point.y);
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    if (pathRefSecond.current) {
      const totalPathLengthSecond = pathRefSecond.current.getTotalLength();

      // Calculate the scroll factor for the second circle
      const scrollFactorSecond =
        totalPathLengthSecond / sectionRef.current.clientHeight;

      // Map the scroll position to a scroll offset along the second path
      const scrollOffsetSecond = scrollPositionSecond * scrollFactorSecond;

      // Update the circle position along the second path
      const circleSecond = document.getElementById("scrollCircleSecond");
      if (circleSecond) {
        const point =
          pathRefSecond.current.getPointAtLength(scrollOffsetSecond);
        circleSecond.setAttribute("cx", point.x);
        circleSecond.setAttribute("cy", point.y);
      }
    }
  }, [scrollPositionSecond]);

  return (
    <div className="font-sans text-black-800">
      <div className="flex justify-center items-center">
        <div
          className="flex justify-center items-center text-black mt-5 py-2 px-3 rounded-lg font-semibold border border-black w-fit"
        >
          <span className="jakarta text-sm">Pricing</span>
        </div>
      </div>

      {/* Header Section */}
      <section className="relative w-full h-screen flex justify-center items-start bg-gray-50 py-4">
        {/* Video Background */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <video
            src={Hero}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover"
          ></video>
        </div>

        {/* Content Overlay */}
        <div className="relative z-10 text-center text-black w-full px-4 sm:px-8 md:px-16 flex flex-col items-center mt-1 ">
          <h1 className="rubik font-bold text-3xl md:text-4xl lg:text-5xl leading-tight">
            CloudEvolve is Free
          </h1>
          <p className="mt-2 text-lg jakarta max-w-2xl mx-auto leading-relaxed">
            We believe savings shouldn't come with risks—or commitments.
          </p>

          <button
            className="bg-[#02AAE2] jakarta text-white px-6 py-3 mt-4 rounded-lg font-semibold flex items-center justify-center gap-3 border border-black hover:bg-[#018bbd] transition-colors duration-300"
            onClick={() => navigate("/bookyour")}
          >
            <span>Unlock Savings</span>
            <img src={rightsidearrow} alt="rightarrow" className="w-5 h-5 ml-2" />
          </button>
        </div>
      </section>



      {/* <div className="mt-8">
          <img src={cloudImage} alt="Cloud" className="w-full h-auto" />
        </div> */}

      {/* Features Section */}
      <section className="md:px-16 lg:px-16 bg-white ">
        {/* <img
          src={price}
          alt="Price Icon"
          className="h-16 w-16 lg:h-16  lg:w-16 lg:ml-52 md:ml-24 xl:ml-80"
        /> */}
        <h2 className="flex justify-center text-center text-2xl md:text-4xl lg:text-4xl font-bold mb-10  rubik lg:ml-36 md:ml-28">
          What Makes Our Pricing Stand Out?
          {/* <img
            src={prc2}
            alt="Price Icon"
            className="h-10 w-10 lg:h-16 lg:w-16 md:h-16 md:w-16 lg:ml-20 md:ml-9 sm:mt-10" */}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {/* Feature 1 */}
          <div className="text-center">
            <img
              src={checkCircleIcon}
              alt="Check Icon"
              className="h-10 w-10 mx-auto"
            />
            <h3 className="rubik font-bold text-lg mt-4 mb-2">
              No Commitments, <br />
              No Risk
            </h3>
            <p className="text-black-400 jakarta">
              Enjoy savings without long-term <br></br>contracts or upfront
              investments.
            </p>
          </div>
          {/* Feature 2 */}
          <div className="text-center">
            <img
              src={checkCircleIcon}
              alt="Check Icon"
              className="h-10 w-10 mx-auto"
            />
            <h3 className="rubik font-bold text-lg mt-4 mb-2">
              Save Up to <br />
              60% Instantly
            </h3>
            <p className="text-black-400 jakarta">
              Reduce your cloud expenses <br></br>without purchasing savings
              plans.
            </p>
          </div>
          {/* Feature 3 */}
          <div className="text-center">
            <img
              src={checkCircleIcon}
              alt="Check Icon"
              className="h-10 w-10 mx-auto"
            />
            <h3 className="rubik font-bold text-lg mt-4 mb-2">
              Direct Discounts on <br />
              AWS Services
            </h3>
            <p className="text-black-400 jakarta">
              Access EC2, RDS, S3, and more <br></br>with built-in discounts.
            </p>
          </div>
        </div>
        <br></br>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 max-w-3xl mx-auto mt-5">
          {/* Feature 4 */}
          <div className="text-center lg:place-self-center">
            <img
              src={checkCircleIcon}
              alt="Check Icon"
              className="h-10 w-10 mx-auto"
            />
            <h3 className="rubik font-bold text-lg mt-4 mb-2">
              Group Buying <br />
              Power
            </h3>
            <p className="text-black-400 jakarta">
              Leverage community-driven <br></br>savings for the best rates.
            </p>
          </div>
          {/* Feature 5 */}
          <div className="text-center lg:place-self-center">
            <img
              src={checkCircleIcon}
              alt="Check Icon"
              className="h-10 w-10 mx-auto"
            />
            <h3 className="rubik font-bold text-lg mt-4 mb-2">
              Flexible <br />
              Plans
            </h3>
            <p className="text-black-400 jakarta">
              Adapt to changes in cloud <br></br>consumption without penalties.
            </p>
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>

      <hr style={{ border: "1px solid #F4F4F4" }} />

      {/* Contract Plan Section */}
      <section>
        <div className="bg-black text-black min-h-screen flex flex-col items-center justify-center p-8 mt-10">
          <h1 className="rubik text-4xl font-bold leading-[48px] text-center">
            Contract Plan
          </h1>
          <p className="text-lg mb-12 text-black-400 jakarta">
            Rates which you can't say no to
          </p>

          <div className=" h-60 w-60 mb-4 mr-5 md:hidden lg:hidden">
            <video
              src={First}
              autoPlay
              loop
              muted
              className="w-full"
            />
          </div>

          <div className="flex flex-col">
            {/* 1st Year */}
            <div className="flex items-center space-x-32" ref={sectionRef}>
              <div className="flex flex-col items-start space-y-4">
                <h2 className="rubik text-2xl font-bold">
                  1st Year{" "}
                  <span className="bg-black-800 px-2 py-1 rounded-lg text-green-400 text-sm">
                    20% off
                  </span>
                </h2>
                <ul className="space-y-2 jakarta">
                  <li className="flex items-center text-lg">
                    <span className="text-green-400 mr-2">
                      <img
                        src={checkCircleIcon}
                        alt="Check Icon"
                        className="h-6 w-6 mx-auto"
                      />
                    </span>{" "}
                    Quick Gains
                  </li>
                  <li className="flex items-center text-lg">
                    <span className="text-green-400 mr-2">
                      <img
                        src={checkCircleIcon}
                        alt="Check Icon"
                        className="h-6 w-6 mx-auto"
                      />
                    </span>{" "}
                    Immediate Discounts
                  </li>
                  <li className="flex items-center text-lg">
                    <span className="text-green-400 mr-2 ">
                      <img
                        src={checkCircleIcon}
                        alt="Check Icon"
                        className="h-6 w-6 mx-auto"
                      />
                    </span>{" "}
                    Monthly Insights
                  </li>
                </ul>
              </div>
              <div className=" h-60 w-60 lg:flex md:flex hidden">
                <video
                  src={First}
                  autoPlay
                  loop
                  muted
                  className="w-full"
                />
              </div>
            </div>

            <div className="relative w-full h-[181px] flex justify-center items-center lg:flex md:flex hidden">
              <svg
                width="398"
                height="181"
                viewBox="0 0 398 181"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  ref={pathRef}
                  d="M394.816 2.58594C395.582 9.86878 393.701 17.3837 390.651 24.3914C383.521 40.797 367.726 55.2422 346.07 65.6978C308.717 83.72 259.176 89.3178 212.923 90.3325C167.493 91.3299 122.883 91.0799 80.8779 103.909C68.204 107.778 56.3378 112.765 46.5746 119.111C4.31894 146.582 3.00977 178.448 3.00977 178.448"
                  stroke="#1B1F1D"
                  stroke-width="5"
                  stroke-linecap="round"
                />

                <circle
                  id="scrollCircle"
                  r="12"
                  fill="white"
                  stroke="black"
                  strokeWidth="10"
                />
              </svg>
            </div>

            {/* 2nd Year */}
            <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row items-center  md:space-x-8 lg:space-x-8 xl:space-x-8">
              {/* 2nd Year */}
              <div className="h-60 w-60 md:h-60 md:w-60 lg:h-60 lg:w-60 md:mt-0 lg:mt-0 xl:mt-0 mt-4 md:mr-0 lg:mr-0 xl:mr-0 mr-4">
                <video
                  src={Second}
                  autoPlay
                  loop
                  muted
                  className="w-full"
                />
              </div>
              <div className=" flex-1 flex-col items-start space-y-4 ">
                <h2 className="rubik text-2xl font-bold mt-4 md:mt-0 lg:mt-0 xl:mt-0 mr-24">
                  2nd Year{" "}
                  <span className="bg-black-800 px-2 py-1 rounded-lg text-green-400 text-sm">
                    30% off
                  </span>
                </h2>
                <ul className="space-y-2 jakarta">
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Balanced Commitment
                  </li>
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Enhanced Discounts
                  </li>
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Quarterly Review Meetings
                  </li>
                </ul>
              </div>
            </div>

            {/* Curve Connector */}
            <div className="relative w-full h-[181px] flex justify-center items-center lg:flex md:flex hidden">
              <svg
                width="398"
                height="181"
                viewBox="0 0 398 181"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  ref={pathRefSecond}
                  d="M2.80922 2.58594C2.04334 9.86878 3.924 17.3837 6.97363 24.3914C14.1043 40.797 29.8987 55.2422 51.5548 65.6978C88.908 83.72 138.449 89.3178 184.702 90.3325C230.132 91.3299 274.742 91.0799 316.747 103.909C329.421 107.778 341.287 112.765 351.05 119.111C393.306 146.582 394.615 178.448 394.615 178.448"
                  stroke="#1B1F1D"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <circle
                  id="scrollCircleSecond"
                  r="12"
                  fill="white"
                  stroke="black"
                  strokeWidth="10"
                />
              </svg>
            </div>

            <div className="h-60 w-60 mb-4 mt-4 md:hidden lg:hidden">
              <video
                src={Third}
                autoPlay
                loop
                muted
                className="w-full"
              />
            </div>
            {/* 3rd Year */}
            <div className="flex items-center space-x-32">
              <div className="flex flex-col items-start space-y-4">
                <h2 className="rubik text-2xl font-bold">
                  3rd Year{" "}
                  <span className="bg-yellow-400 px-2 py-1 rounded-lg text-black text-sm">
                    30% off
                  </span>
                </h2>
                <ul className="space-y-2 jakarta">
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Long Term Savings
                  </li>
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Maximum Discounts
                  </li>
                  <li className="flex items-center text-lg">
                    <img
                      src={checkCircleIcon}
                      alt="Check Icon"
                      className="h-6 w-6 mr-2"
                    />
                    Annual Strategy Sessions
                  </li>
                </ul>
              </div>
              <div className="h-60 w-60 lg:flex md:flex hidden">
                <video
                  src={Third}
                  autoPlay
                  loop
                  muted
                  className="w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Bonus Perks Section */}
      <section
        className="bg-white py-8 px-4 sm:px-6 lg:px-8 mb-10"
        style={{ marginTop: "17px" }}
      >
        <h2 className="rubik text-center text-2xl font-bold text-black-900 mb-10 ">
          Bonus Perks for Every Plan
        </h2>
        <div className="flex flex-col md:flex-row lg:flex-row justify-center items-center gap-8">
          <div
            className="shadow-md py-10 px-14 text-center max-w-sm flex flex-col items-center justify-between"
            style={{
              border: "2px solid black",
              borderRadius: "25px",
              backgroundColor: "#FAFEFF",
              height: "270px",
              width: "320px",
            }}
          >
            <img
              src={checkCircleIcon}
              alt="Perk Icon"
              className="w-10 h-10 mx-auto mb-4"
            />
            <h3
              className="rubik text-lg font-bold text-black-900"
              style={{ height: "70px" }}
            >
              100% <br /> Transparency
            </h3>
            <div>
              <p className="text-sm mt-2 jakarta" style={{ height: "70px" }}>
                Clear insights into your <br /> savings and usage.
              </p>
            </div>
          </div>

          <div
            className="shadow-md py-10 px-14 text-center max-w-sm flex flex-col items-center justify-between"
            style={{
              border: "2px solid black",
              borderRadius: "25px",
              backgroundColor: "#FDFCFF",
              height: "270px",
              width: "320px",
            }}
          >
            <img
              src={checkCircleIcon}
              alt="Perk Icon"
              className="w-10 h-10 mx-auto mb-4"
            />
            <h3
              className="rubik text-lg font-bold text-black-900"
              style={{ height: "70px" }}
            >
              24/7 <br /> Support
            </h3>
            <div>
              <p className="text-sm mt-2 jakarta" style={{ height: "70px" }}>
                Always here to help, no matter <br /> what time zone you’re in.
              </p>
            </div>
          </div>

          <div
            className="shadow-md py-10 px-14 text-center max-w-sm flex flex-col items-center justify-between"
            style={{
              border: "2px solid black",
              borderRadius: "25px",
              backgroundColor: "#FDFCFF",
              height: "270px",
              width: "320px",
            }}
          >
            <img
              src={checkCircleIcon}
              alt="Perk Icon"
              className="w-10 h-10 mx-auto mb-4"
            />
            <h3
              className="rubik text-lg font-bold text-black-900"
              style={{ height: "70px" }}
            >
              Scalable as <br /> You Grow
            </h3>
            <div>
              <p className="text-sm mt-2 jakarta" style={{ height: "70px" }}>
                Upgrade your plan or <br /> switch without hassle.{" "}
              </p>
            </div>
          </div>
        </div>
      </section>

      <hr style={{ border: "5px solid #F4F4F4" }} />

      {/* FAQ Section */}
      <section className="py-16 px-2 bg-white mb-10">
        <h2 className="rubik text-4xl font-bold leading-[48px] text-center">
          Frequently Asked Questions
        </h2>
        <div
          className="max-w-4xl mx-auto space-y-4 group border mb-10 "
          style={{
            border: "2px solid black",
            borderRadius: "25px",
            padding: "20px",
            marginTop: "45px",
          }}
        >
          {/* FAQ 1 */}
          <details className=" rounded-lg p-4 shadow-sm ">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              Are there any commitments required?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className=" rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              Is there any fee to join?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className=" rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              What makes Cloudevolve different from other platforms?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              How often does pricing adjust to usage changes?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              Can I access detailed reports on my spending?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              What happens if my cloud usage changes?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="jakarta flex justify-between items-center font-bold text-black-800 cursor-pointer">
              Are there any hidden fees?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 leading-relaxed jakarta">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
        </div>
      </section>
    </div>
  );
};

export default PricingPage;