import React, { useState } from 'react';
import rightsidearrow from '../assets/warehouse/rightsidearrow.webp';
// import firstCardimg from '../assets/warehouse/firstCardimg.webp';
// import BaseIllustration1 from '../assets/warehouse/BaseIllustration1.webp';
import awslogo from '../assets/warehouse/awslogo.webp';
import arrowblc from '../assets/warehouse/arrowblc.webp';
import discount from '../assets/warehouse/discount.webp';
import savings from '../assets/warehouse/savings.webp';
import cost from '../assets/warehouse/cost.webp';
import commitment from '../assets/warehouse/commitment.webp';
import { useNavigate } from "react-router-dom";
import Icon1 from '../assets/Icon1.webp';
import Group1 from '../assets/Group1.webp';
// import Hand from "../assets/hand.webp";
// import Vector624 from '../assets/Vector 624.webp';
import Cloud from '../assets/cloud.webp';
import Group2 from "../assets/group2.webp";
import Hand from "../assets/get started.gif";
import Hero from "../assets/saving.mp4";

const Warehouse = () => {
  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);


  const cardData = [
    {
      title: "Discounts",
      description: "Immediate savings on AWS services without long-term plans.",
      icon: discount,
      alt: "Discount Icon",
    },
    {
      title: "No Commitment",
      description: "Use services flexibly with no binding contracts.",
      icon: commitment,
      alt: "No Commitment Icon",
    },
    {
      title: "Cost Transparency",
      description: "Clear visibility into savings for better budgeting.",
      icon: cost,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Personalized Savings",
      description: "Customized discounts based on your usage patterns.",
      icon: savings,
      alt: "Personalized Savings Icon",
    },
  ];



  return (
    <>
      <div className="relative h-screen md:h-screen sm:h-[40vh] xs:h-[10vh] w-full">
        {/* Video as background */}
        <video
          src={Hero}
          autoPlay
          loop
          muted
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 
      w-full 
      max-w-full 
      md:w-full md:h-full 
      object-cover 
      aspect-[16/9] md:aspect-auto"
        />





      {/* Content on top of the video */}
      <div className="absolute top-0 left-0 w-full z-10">
        <div className="flex flex-col items-center justify-start p-5 sm:p-8 md:p-0">
          {/* Platform Tag */}
          <div className="py-2 px-3 rounded-lg font-semibold border border-black text-black mt-4">
            <span className="jakarta text-xs md:text-sm lg:text-base">Platform</span>
          </div>

          {/* Title */}
          <h2
            className="rubik text-sm sm:text-lg md:text-2xl lg:text-4xl mt-4 lg:mt-6 font-bold text-center"
            style={{ color: "#000000" }}
          >
            Savings Warehouse
          </h2>

          {/* Subtitle */}
          <h2
            className="mt-2 md:mt-3 lg:mt-4 text-xs sm:text-sm md:text-lg lg:text-xl w-[100%] sm:w-[85%] md:w-[75%] lg:w-[60%] mx-auto text-center jakarta"
            style={{ color: "#222222" }}
          >
            Get benefits of Savings plan without purchasing one.
          </h2>
        </div>

        {/* Button */}
        <div className="flex justify-center items-center mt-0 md:mt-10 lg:mt-6">
          <div
            className="bg-[#02AAE2] text-white jakarta px-4 py-3 rounded-lg font-semibold flex items-center gap-3 cursor-pointer border border-black"
            onClick={() => navigate("/bookyour")}
          >
            <span className="text-xs sm:text-sm md:text-base">Get Your Discounts</span>
            <img
              src={rightsidearrow}
              className="w-4 h-4 sm:w-5 sm:h-5 md:w-6 md:h-6"
              alt="Arrow Icon"
            />
          </div>
        </div>
      </div>
    </div >






      {/* Discount Section */ }
      < div className = "text-center mt-10 pb-24" >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Slash that bill!
        </h3>
        <h3 className="mt-2 jakarta" style={{ color: "#222222" }}>
          Get AWS service discounts without the commitment.
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 px-16 mt-10">
          {[...Array(12)].map((_, index) => (
            <div
              key={index}
              className="rounded-2xl p-4 flex flex-col space-y-4 max-w-xs border border-gray-300"
              onMouseEnter={() => setHoveredIndex(index)} // Set the hovered card's index
              onMouseLeave={() => setHoveredIndex(null)} // Reset the hover state
            >
              <div className="flex justify-between items-center">
                <div className="text-left">
                  <h4 className="text-lg text-black font-bold jakarta">EC2</h4>
                  <p className="text-sm text-black-400 jakarta">(Linux/Unix)</p>
                </div>
                <div className="flex items-center">
                  <img src={awslogo} alt="AWS Logo" className="h-6" />
                </div>
              </div>
              <div className="flex items-center mt-6 relative">
                {/* Original Button */}
                <img
                  src={arrowblc}
                  alt="Arrow Icon"
                  className="h-10 mr-4"
                  onClick={() => navigate("/bookyour")}
                />

                {/* "Get Started" Button */}
                {hoveredIndex === index && (
                  <div className="battery-animation absolute inset-0 flex items-center justify-center">
                    <button className="relative w-full max-w-xs px-4 py-2  text-white rounded  overflow-hidden">
                      <span className="loading-bar absolute inset-0  h-full"></span>
                      <span className="relative z-10" onClick={() => navigate("/bookyour")}>Get Started</span>
                    </button>
                  </div>

                )}
              </div>
            </div>
          ))}
        </div>



  {/* Uncomment the div below if you need the pricing details */ }
  {/* <div className="flex flex-col ml-4 items-start">
    <span className="text-sm text-black line-through jakarta" style={{
      color: '#C0C0C0'
    }}>₹2,000</span>
    <span className="text-sm text-black font-extrabold jakarta">₹5,500</span>
  </div> */}
      </div >
  <hr style={{ border: '2px solid #F4F4F4' }} />
{/* Benefits Section */ }
      <div className="text-center mt-10 pb-8" >
        <h3 className="rubik text-4xl mt-[70px] font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Benefits of Savings Warehouse
        </h3>
        <h3 className="mt-2 jakarta mb-8" style={{ color: "#222222" }}>
          Cloudevolve offers instant AWS discounts, to optimize cloud costs effectively.
        </h3>


        <div className="relative flex flex-col justify-center items-center">
          {/* Horizontal Line */}
          <div className="absolute lg:top-52 md:top-52 lg:left-0 md:left-0 lg:w-[584px]  lg:ml-[220px] xl:ml-[380px] md:ml-24 md:w-[584px] lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4"></div>

          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-6 md:top-5 lg:h-full md:h-full lg:w-0.5 md:w-0.5 bg-gray-25 transform -translate-x-1/2"></div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-10 mt-8 px-4 lg:px-40">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center w-full"
              >
                <div className="h-16 w-16 flex items-center justify-center rounded">
                  <img src={card.icon} alt={card.alt} className="w-14 h-14" />
                </div>
                <h4 className="text-lg font-bold mt-4 jakarta">{card.title}</h4>
                <p
                  className="text-sm text-gray-600 mt-2 jakarta"
                  style={{ color: "#222222", width: '250px' }}
                >
                  {card.description}
                </p>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="flex flex-wrap justify-center gap-10 mt-12 px-4 lg:px-20">
          {cardData.map((card, index) => (
            <div
              key={index}
              className="flex flex-col items-center text-center w-72" // Fixed width for better alignment
            >
              <div className="h-16 w-16 flex items-center justify-center rounded">
                <img
                  src={card.icon}
                  alt={card.alt}
                  className="w-14 h-14"
                />
              </div>
              <h4 className="text-lg font-bold mt-4">{card.title}</h4>
              <p className="text-sm text-gray-600 mt-2 font-semibold" style={{ color: "#222222", width: '90%' }}>{card.description}</p>
            </div>
          ))}
        </div> */}
      </div>

      <div className="absolute left-0 right-0 h-4"></div>

      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          className="block sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />

        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[65px] lg:mb-[60px] lg:w-52 lg:h-52 xl:mb-[65px] xl:h-72 xl:w-[350px] xl:mr-0 w-24 h-24 md:w-40 md:h-40 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44 h-24 md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2 bg-black rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold font-rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-20 md:ml-0 xl:text-4xl lg:text-4xl md:text-3xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you commitment phobic?
          </h3>
          <p className="font-normal jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-20 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-xl text-white mb-4 text-center sm:text-left">
            Join our community for a situationship!
          </p>
          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-20 xl:ml-20">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black jakarta text-start text-xs font-semibold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              />
            </button>
          </div>
        </div>
      </div>






    </>
  );
};

export default Warehouse;
