import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import bookcvr from "../assets/homeimg/bookcvr.webp";
import CalendlyEmbed from "./Calendly";

const Bookyourdate = () => {
  // const [selectedDate, setSelectedDate] = useState(null);
  // const [showForm, setShowForm] = useState(false);

  // Function to filter time between 9:30 AM and 6:30 PM
  // const filterTime = (time) => {
  //   const selectedTime = time.getHours() * 60 + time.getMinutes();
  //   const startTime = 9 * 60 + 30; // 9:30 AM
  //   const endTime = 18 * 60 + 30; // 6:30 PM
  //   return selectedTime >= startTime && selectedTime <= endTime;
  // };

  return (


    <div className="min-h-screen flex items-center justify-center">
      <div className=" w-full max-w-4xl bg-white">
      <div className="flex justify-center items-center ">
        <div
          className="flex justify-center items-center text-black mt-5 py-2 px-3 rounded-lg font-semibold border border-black w-fit"
        >
          <span className="jakarta text-sm">Get in touch</span>
        </div>
      </div>
        {/* Header Section */}
        <div className="text-center mt-6 mb-8">
          <h2 className="text-4xl font-bold" style={{ color: "#000000" }}>
            Book your date with Savings!
          </h2>
          <h2
            className="font-semibold mt-5 w-[70%] mx-auto text-center"
            style={{ color: "#222222" }}
          >
            Ready to dive into a world of cloud savings? Scheduling a demo with
            us is your first step toward slashing those pesky AWS bills! Choose
            a date that works for you on our calendar, and let’s embark on this
            journey together.
          </h2>
        </div>

        {/* Main Content */}
        {/* <div className="flex flex-col md:flex-row items-stretch rounded-lg shadow-lg border border-gray-200"> */}

        {/* <div className="w-full md:w-1/2 ">
            <div>
              <div className="flex items-center pt-3 pl-5">
              {showForm && (
  <span 
    className="mr-4 cursor-pointer" 
    onClick={() => setShowForm(false)} // Add onClick to update state
  >
    <MdArrowBack />
  </span>
)}      
                <img src={logo} alt="Logo" className="h-6 w-auto" />
                <span className="ml-2 font-semibold text-2xl">Cloudevolve</span>
              </div>
              
              <hr className="my-4 border-gray-300" />
              <div className="pl-5">
                <h3 className="text-lg font-bold mb-4">Product Demo</h3>
                <div className="flex items-center mb-4">
                  <img src={phone} alt="phone" className="h-4 w-auto" />
                  <span className="ml-2 text-bold text-sm">30 min</span>
                </div>
                <div className="flex items-center">
                  <img src={clock} alt="clock" className="h-4 w-auto" />
                  <span className="ml-2 text-bold text-sm">Phone call</span>
                </div>
                <p className="mt-4 text-sm">
                  This is an example of a meeting you would have with a potential
                  customer to demonstrate your product.
                </p>
              </div>
            </div>
          </div> */}

        {/* Vertical Divider */}
        <div className="hidden md:block w-px bg-gray-200"></div>
        <CalendlyEmbed />
        {/* Right Section */}
        {/* <div className="w-full md:w-1/2 p-6">
            {showForm ? (
              <form>
                <h3 className="text-lg font-bold mb-4">Enter Details</h3>
                <label className="block mb-2 mt-4 font-semibold">
                  Name *
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                    required
                  />
                </label>
                <label className="block mb-2 mt-4 font-semibold">
                  Email *
                  <input
                    type="email"
                    className="border rounded w-full p-2"
                    required
                  />
                </label>
                <button
                  type="submit"
                  className="text-white font-semibold py-1 px-3 rounded-2xl"
                  style={{border:'1px solid blue',color:'blue'}}
                >
                  Add Guests
                </button>
                <label className="block mb-2 mt-4 font-semibold">
                  HQ Country
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                  />
                </label>
                <label className="block mb-2 mt-4 font-semibold">
                  Are you on free AWS/GCP credits? *
                   <br />
                    <input type="radio" name="credits" value="yes" /> Yes
                    <br />
                    <input
                      type="radio"
                      name="credits"
                      value="no"                      
                    />{" "}
                    No
              
                </label>
                <label className="block mb-2 mt-4 font-semibold">
                  Monthly AWS/GCP spend in US dollars *
                  <input
                    type="number"
                    className="border rounded w-full p-2"
                    required
                  />
                </label>
                <label className="block mb-4 mt-4 font-semibold">
                  Send text messages to
                  <input
                    type="text"
                    className="border rounded w-full p-2"
                  />
                </label>
                <p className="text-sm  mt-4 mb-2">
                By proceeding, you confirm that you have read and agree to <a href="/terms" className="text-blue-500"> Calendly's Terms of Use and Privacy Notice.</a>
  </p>
                <button
                  type="submit"
                  className="bg-blue-500 mt-2 text-white font-semibold py-1 px-3 rounded-2xl"
                >
                  Schedule Event
                </button>
                <p className="text-sm  mt-4">
                You will be redirected to an external site.
</p>
              </form>
            ) : (
              <div>
                <h3 className="text-lg font-bold mb-4">Select a Date & Time</h3>
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  inline
                  dateFormat="MMMM d, yyyy"
                  className="border rounded-lg"
                  minDate={new Date()}
                  filterTime={filterTime}
                  showTimeSelect
                />
                <button
                  onClick={() => setShowForm(true)}
                  className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
                >
                  Next
                </button>
                <h3 className="mt-4 font-bold text-sm">Time zone:</h3>
                <p className="mt-1 mb-2 text-sm flex items-center">
                  <img src={globe} alt="globe" className="h-4 w-auto mr-2" />
                  Central European Time (8:11pm)
                  <img src={drop} alt="drop" className="h-4 w-auto ml-2" />
                </p>
                
              </div>
            )}
          </div> */}
        {/* </div> */}
        <div className="flex justify-center mt-5 mb-5">
          <img
            src={bookcvr}
            alt="bookcvr"
            className="max-w-full h-auto"
          />
        </div>

        {/* Frequency Question Section */}
        <div className="py-6 px-2 bg-white">
          <h2 className="text-center text-3xl font-bold mb-12">
            Frequently Asked Questions
          </h2>
          <div className="max-w-4xl mx-auto space-y-4 group border " style={{ border: "1px solid black", borderRadius: "25px", padding: "20px" }}>
            {/* FAQ 1 */}
            <details className=" rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                Are there any commitments required?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className=" rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                Is there any fee to join?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className=" rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                What makes Cloudevolve different from other platforms?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className="rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                How often does pricing adjust to usage changes?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className="rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                Can I access detailed reports on my spending?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className="rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                What happens if my cloud usage changes?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
            <details className="rounded-lg p-4 shadow-sm">
              <summary className="flex justify-between items-center font-semibold text-black-800 cursor-pointer">
                Are there any hidden fees?
                <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                  +
                </span>
              </summary>
              <p className="mt-3 text-black-600 leading-relaxed">
                No, we believe in flexibility! You can enjoy savings without any
                long-term commitments.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bookyourdate;
