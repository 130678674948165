import React, { useState, useEffect } from 'react'
import ContactImage from "../assets/Contactimg.webp";
import cloudImage from "../assets/Base Illustration.webp";
import rightsidearrow from '../assets/warehouse/rightsidearrow.webp';
import { sendmail } from "../API/api";
import { post } from '../API/apiCommon';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [query, setQuery] = useState("");
  // const [formErrors, setFormErrors] = useState({});
  const [value, setValue] = useState({ label: "+91", value: "+91" })
  const countryOptions = [
    { label: "+1", value: "+1" },
    { label: "+7", value: "+7" },
    { label: "+20", value: "+20" },
    { label: "+27", value: "+27" },
    { label: "+30", value: "+30" },
    { label: "+31", value: "+31" },
    { label: "+32", value: "+32" },
    { label: "+33", value: "+33" },
    { label: "+34", value: "+34" },
    { label: "+36", value: "+36" },
    { label: "+39", value: "+39" },
    { label: "+40", value: "+40" },
    { label: "+44", value: "+44" },
    { label: "+46", value: "+46" },
    { label: "+47", value: "+47" },
    { label: "+48", value: "+48" },
    { label: "+49", value: "+49" },
    { label: "+52", value: "+52" },
    { label: "+54", value: "+54" },
    { label: "+55", value: "+55" },
    { label: "+61", value: "+61" },
    { label: "+64", value: "+64" },
    { label: "+65", value: "+65" },
    { label: "+81", value: "+81" },
    { label: "+82", value: "+82" },
    { label: "+86", value: "+86" },
    { label: "+91", value: "+91" },
    { label: "+92", value: "+92" },
    { label: "+93", value: "+93" },
    { label: "+94", value: "+94" },
    { label: "+95", value: "+95" },
    { label: "+98", value: "+98" },
    { label: "+212", value: "+212" },
    { label: "+213", value: "+213" },
    { label: "+216", value: "+216" },
    { label: "+218", value: "+218" },
    { label: "+234", value: "+234" },
    { label: "+250", value: "+250" },
    { label: "+251", value: "+251" },
    { label: "+254", value: "+254" },
    { label: "+255", value: "+255" },
    { label: "+256", value: "+256" },
    { label: "+260", value: "+260" },
    { label: "+263", value: "+263" },
    { label: "+351", value: "+351" },
    { label: "+352", value: "+352" },
    { label: "+353", value: "+353" },
    { label: "+354", value: "+354" },
    { label: "+355", value: "+355" },
    { label: "+356", value: "+356" },
    { label: "+357", value: "+357" },
    { label: "+358", value: "+358" },
    { label: "+359", value: "+359" },
    { label: "+370", value: "+370" },
    { label: "+371", value: "+371" },
    { label: "+372", value: "+372" },
    { label: "+373", value: "+373" },
    { label: "+374", value: "+374" },
    { label: "+375", value: "+375" },
    { label: "+376", value: "+376" },
    { label: "+377", value: "+377" },
    { label: "+378", value: "+378" },
    { label: "+380", value: "+380" },
    { label: "+381", value: "+381" },
    { label: "+382", value: "+382" },
    { label: "+383", value: "+383" },
    { label: "+385", value: "+385" },
    { label: "+386", value: "+386" },
    { label: "+387", value: "+387" },
    { label: "+389", value: "+389" },
    { label: "+420", value: "+420" },
    { label: "+421", value: "+421" },
    { label: "+423", value: "+423" },
    { label: "+852", value: "+852" },
    { label: "+853", value: "+853" },
    { label: "+855", value: "+855" },
    { label: "+856", value: "+856" },
    { label: "+880", value: "+880" },
    { label: "+886", value: "+886" },
    { label: "+960", value: "+960" },
    { label: "+961", value: "+961" },
    { label: "+962", value: "+962" },
    { label: "+963", value: "+963" },
    { label: "+964", value: "+964" },
    { label: "+965", value: "+965" },
    { label: "+966", value: "+966" },
    { label: "+967", value: "+967" },
    { label: "+968", value: "+968" },
    { label: "+970", value: "+970" },
    { label: "+971", value: "+971" },
    { label: "+972", value: "+972" },
    { label: "+973", value: "+973" },
    { label: "+974", value: "+974" },
    { label: "+975", value: "+975" },
    { label: "+976", value: "+976" },
    { label: "+977", value: "+977" },
    { label: "+992", value: "+992" },
    { label: "+993", value: "+993" },
    { label: "+994", value: "+994" },
    { label: "+995", value: "+995" },
    { label: "+996", value: "+996" },
    { label: "+998", value: "+998" }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // const changeHandler = value => {
  //   setValue(value)
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear previous errors
    // setFormErrors({});

    let errors = {};
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // Validate name: must not be empty
    if (!name.trim()) {
      // errors.name = "Full name is required.";
      toast.error("Full name is required");
    } else
      if (!email.trim()) {
        toast.error("Email address is required");
      } else if (!emailPattern.test(email)) {
        toast.error("Please enter a valid email address");
      } else
        // Validate mobile: must be 10 digits
        if (!mobile.trim()) {
          toast.error("Contact number is required");
        } else if (!/^[0-9]{10}$/.test(mobile)) {
          toast.error("Contact number must be 10 digits");
        } else

          // Validate query: must not be empty
          if (!query.trim()) {
            toast.error("Query is required");
          } else

            if (Object.keys(errors).length > 0) {
              // setFormErrors(errors);  // Set errors to display on form
            } else {
              // alert("Form submitted successfully!");
              // Here you can handle the form submission logic, like sending the data to an API or server
              const payload = {
                name: name,
                email_address: email,
                mobile_number: mobile,
                query: query,
                country_code: value.value
              };
              try {
                console.log(payload, "103")
                //return
                //setLoader(true);
                const response = await post(sendmail, payload);
                console.log(response, "176")

                if (response && response.data && response.data.statusCode === 200) {
                  toast.success(response.data.message);
                  setValue({ label: "+91", value: "+91" })
                  setName("");
                  setEmail("");
                  setMobile("");
                  setQuery("");

                } else {

                  //toast.error(response.data.message);
                }
              } catch (error) {
                // setLoader(false);
                console.error('Error calling the API:', error);
              } finally {
                // setLoader(false);
              }
              // }
            };

  }


  return (
    <div>
      <div className="flex flex-col md:flex-row justify-center items-center p-6 md:ml-[15%] lg:ml-[15%] md:mr-[15%] lg:mr-[15%]">
        {/* Contact Form Section */}
        <div className="w-full md:w-1/2 p-4">
          <h2 className="text-2xl font-bold rubik text-gray-800 mt-6 mb-2">Contact us</h2>
          <p className="text-gray-600 mb-6 jakarta">Send in your query to us, and let the savings rain!</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                placeholder="Full name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {/* {formErrors.name && <p className="text-red-500 text-sm">{formErrors.name}</p>} */}
            </div>

            <div>
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              {/* {formErrors.email && <p className="text-red-500 text-sm">{formErrors.email}</p>} */}
            </div>
            <div className="flex items-center gap-0">
              <Select
                options={countryOptions}
                value={value}
                onChange={(selectedOption) => setValue(selectedOption)}
                className="w-44"
                styles={{
                  control: (base) => ({
                    ...base,
                    height: '48px', // Height of 48px
                    //padding: '8px', // Padding inside the dropdown
                    border: '1px solid #d1d5db', // Border color (gray-300 equivalent)
                    borderRadius: '0.375rem', // Rounded corners
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: '#3b82f6', // Blue border on hover
                    },
                  }),
                }}
              />

              <input
                type="tel"
                placeholder="Contact number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                className="w-full ml-1 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>



            {/* {formErrors.mobile && <p className="text-red-500 text-sm">{formErrors.mobile}</p>} */}
            <div>
              <textarea
                placeholder="Enter your query here"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                className="w-full p-3 h-28 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              ></textarea>
              {/* {formErrors.query && <p className="text-red-500 text-sm">{formErrors.query}</p>} */}
            </div>

            <button
              type="submit"
              className="bg-[#02AAE2] text-white jakarta px-3 py-3 rounded-lg font-semibold flex items-center justify-center gap-3 w-full"
              style={{
                transition: "background-color 0.3s",
              }}
            >
              <span>Inquire now</span>
              <img src={rightsidearrow} alt="rightarrow" className="w-5 h-5" />
            </button>
          </form>
        </div>

        <div className="hidden md:block w-full md:w-1/4" style={{ marginLeft: "40px" }}>
          <img
            src={ContactImage}
            width={500}
            alt="Contact Icon"
            className="max-w-full h-30px rounded-md shadow-md"
          />
        </div>

      </div>

      <div className="mt-8">
        <img src={cloudImage} alt="Cloud" className="w-full h-auto" />
      </div>

      <section className="py-16 px-2 bg-white">
        <h2 className="text-center text-4xl rubik font-bold mb-12">
          Frequently Asked Questions
        </h2>
        <div className="max-w-4xl mx-auto space-y-4 group border " style={{ border: "2px solid black", borderRadius: "25px", padding: "20px" }}>
          {/* FAQ 1 */}
          <details className=" rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center font-bold text-black-800 rubik cursor-pointer">
              Are there any commitments required?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className=" rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              Is there any fee to join?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className=" rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              What makes Cloudevolve different from other platforms?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              How often does pricing adjust to usage changes?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              Can I access detailed reports on my spending?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              What happens if my cloud usage changes?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
          <details className="rounded-lg p-4 shadow-sm">
            <summary className="flex justify-between items-center rubik font-bold text-black-800 cursor-pointer">
              Are there any hidden fees?
              <span className="text-black-500 group-open:rotate-45 transform transition-transform duration-200">
                +
              </span>
            </summary>
            <p className="mt-3 text-black-600 jakarta leading-relaxed">
              No, we believe in flexibility! You can enjoy savings without any
              long-term commitments.
            </p>
          </details>
        </div>
      </section>
      <ToastContainer
        position="top-right"   // You can change the position
        autoClose={5000}       // Auto close after 5 seconds
        hideProgressBar={false} // Show/hide progress bar
        newestOnTop={false}
        closeOnClick
        rtl={false}            // For right-to-left languages
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ContactForm;