import React, { useState } from 'react';
import logo from "../assets/logo.webp";
import downdrop from "../assets/navbar/downdrop.webp";
import Vector from "../assets/navbar/Vector.webp";
import savings from "../assets/navbar/savings.webp";
import cloud from "../assets/navbar/cloud.webp";
import warehouse from "../assets/navbar/warehouse.webp";
import faq from "../assets/navbar/faq.webp";
import blg from "../assets/navbar/blg.webp";
import casestu from "../assets/navbar/casestu.webp";
import document from "../assets/navbar/cloud.webp";
import Twitter from '../assets/twitter.webp';
import Facebook from '../assets/facebook.webp';
import Internet from '../assets/internet.webp';
import Insta from '../assets/insta.webp';
import Youtube from '../assets/youtube.webp';

import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const [dropdownState, setDropdownState] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const navbarConfig = {
    logo,
    brandName: "",
    menuItems: [
      { name: "About", link: "/aboutus" },
      {
        name: "Platform",
        dropdown: [
          { name: "Cloud 360", link: "/platform/Cloud360", path: cloud, subname: 'Subcopy if needed', flag: 2 },
          { name: "Savings Warehouse", link: "/platform/warehouse", path: warehouse, subname: 'Subcopy if needed', flag: 1 },
          { name: "Savings Co-Pilot", link: "#", path: savings, subname: 'Subcopy if needed', flag: 3 },
        ],
      },
      { name: "Services", link: "/platform/cloudmanagedservices" },
      { name: "Pricing", link: "/pricing" },
      {
        name: "Resources",
        dropdown: [
          { name: "FAQs", path: faq, link: "/Faq", subname: 'Subcopy if needed' },
          { name: "Blogs", path: blg, link: "/Blogs", subname: 'Subcopy if needed' },
          { name: "Case Studies", path: casestu, link: "#resources2", subname: 'Subcopy if needed' },
          { name: "Documentation", path: document, link: "", subname: 'Subcopy if needed' }
        ],
      },
      { name: "Testimonials", link: "/Testimonials" },
    ],
    buttons: [
      {
        label: "Get in touch",
        // action: "#get-in-touch",
        action: "/contactus",
        style: "bg-[#02AAE2] text-white",
      },
      {
        label: "Sign in",
        action: "#sign-in",
        style: "border border-gray-400 text-gray-700",
      },
    ],
  };

  const renderDropdown = (dropdown, isOpen) => {
    console.log({ isOpen, dropdown }, "isOpen");
    return (
      <ul
        className={`absolute left-0 mt-4 border border-black bg-[#FAFAFA] shadow-lg rounded-xl flex justify-between flex-row font-semibold z-10 ${isOpen ? "block" : "hidden"}`}
        style={{
          width: dropdown?.[0]?.flag === 2 ? "870px" : "1160px", // Adjust width dynamically
          padding: "15px", // Add padding for better spacing
          marginLeft: dropdown?.[0]?.flag === 2 ? "-170px" : "-675px", // Adjust margin dynamically
        }}
      >
        {dropdown?.map((item, idx) => (
          <li
            key={idx}
            onClick={() => {
              if (item.name === "Documentation") {
                // Open documentation link
                window.open("https://docs.cloudevolve.com/docs/intro", "_blank");
              } else {
                // Navigate to other links
                navigate(item.link);
              }
              setDropdownState(null); // Close dropdown
            }}
            className="flex items-center px-4 py-2 hover:bg-gray-100 cursor-pointer space-x-4"
          >
            {/* Left part: Image or Icon */}
            {item.path && (
              <div
                className="hidden lg:block" // Hide on small screens
                style={{
                  borderRadius: "8px",
                  border: "0.15px solid #222",
                  padding: "6px",
                  background: "#FFF",
                }}
              >
                <img
                  src={item.path}
                  alt={item.name || "Icon"}
                  className="h-6 w-6" // Adjust icon size
                />
              </div>
            )}
            {/* Center part: Text */}
            <div className="flex flex-col text-left">
              <span className="text-sm">{item.name}</span>
              {/* Subtext for larger screens */}
              {item.subname && (
                <p className="text-xs mt-1 hidden lg:block font-normal">
                  {item.subname}
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>







    );
  };

  const renderDropdown1 = (dropdown, isOpen) => {
    return (
      <ul
        className={`left-0 rounded-md flex flex-col items-center jakarta px-3 text-sm z-50 ${isOpen ? "block" : "hidden"}`}
        style={{
          width: "100%", // Full width for better spacing
          textAlign: "center", // Center align text
          position: "", // Ensure it appears on top
          top: "100%", // Position below the parent
        }}
      >
        {dropdown.map((item, idx) => (
          <li
            key={idx}
            role="button"
            onClick={() => {
              navigate(item.link);
              setDropdownState(null);
            }}
            className="flex flex-col items-center space-y-2 px-2 py-2 hover:bg-gray-700 w-full cursor-pointer"
          >
            <span className="flex flex-row items-center">
              <button
                type="button"
                className="text-center font-medium jakarta focus:outline-none"
              >
                {item.name}
              </button>
              {item.flag === 3 && (
                <span className="ml-2 font-bold text-red-500">
                  Coming Soon
                </span>
              )}
            </span>
          </li>
        ))}
      </ul>

    );
  };




  const toggleDropdown = (name) => {
    setDropdownState((prev) => (prev === name ? null : name));
  };

  const handleMenuClick = (name) => {
    setActiveMenu(name);
  };

  return (
    <nav className="bg-white px-0 py-2 flex items-center lg:justify-center lg-max:justify-evenly mt-2 relative" >
      {/* Logo and Brand */}
      <div className="flex items-center mr-6 cursor-pointer" onClick={() => navigate('/home')}>
        <img src={navbarConfig.logo} alt="Logo" className="h-5 w-auto" />
        <span className="ml-2 mr-6 text-2xl rubik">{navbarConfig.brandName}</span>
      </div>

      {/* Large Screen Menu */}
      <ul
        className={`hidden lg:flex rubik items-center space-x-12 font-medium text-base mt-2 z-[9999]`}
        style={{ color: "#222222" }}
      >
        {navbarConfig.menuItems.map((item, idx) => (
          <li
            key={idx}
            className="relative hover:text-black-900 cursor-pointer dropdown-container"
          >
            {item.dropdown ? (
              <>
                <span
                  onClick={() => {
                    toggleDropdown(item.name.toLowerCase());
                    handleMenuClick(item.name);
                  }}
                  className={`flex items-center ${activeMenu === item.name ? "underline font-semibold" : ""
                    }`}
                >
                  {item.name}
                  <img
                    src={downdrop}
                    alt="Dropdown Icon"
                    className={`ml-1 mt-1 w-4 h-4 transform transition-transform duration-300 ${dropdownState === item.name.toLowerCase() ? "rotate-180" : ""
                      }`}
                  />
                </span>
                {renderDropdown(
                  item.dropdown,
                  dropdownState === item.name.toLowerCase()
                )}
              </>
            ) : (
              <a
                href={item.link}
                onClick={() => handleMenuClick(item.name)}
                className={`${activeMenu === item.name ? "underline font-semibold" : ""
                  }`}
              >
                {item.name}
              </a>
            )}
          </li>
        ))}

        {/* Large Screen Buttons */}
        <div className="flex space-x-4">
          {navbarConfig.buttons.map((button, idx) => (
            <button
              style={{ border: "1px solid black" }}
              key={idx}
              className={`${button.style} px-4 py-2 jakarta rounded-md text-sm`}
            >
              <a href={button.action}>{button.label}</a>
            </button>
          ))}
        </div>
      </ul>


      {/* Small Screen Menu Toggle */}
      {!isMenuOpen ? (
        <button onClick={() => setIsMenuOpen(true)} className="lg:hidden">
          <img src={Vector} alt="Menu Icon" className="h-4 w-4" />
        </button>
      ) : (
        <button
          type="button"
          className="lg:hidden close jakarta text-gray-700"
          aria-label="Close"
          onClick={() => setIsMenuOpen(false)}
        >
          <span aria-hidden="true" className="text-2xl">
            ×
          </span>
        </button>
      )}

      {/* Small Screen Dropdown Menu */}
      <div
        className={`${isMenuOpen ? "block" : "hidden"
          } lg:hidden absolute top-14 left-0 w-full bg-white shadow-lg z-[99]`}
      >
        <ul className="flex flex-col space-y-4 px-6 py-4 bg-white text-gray-800 items-center jakarta">
          {navbarConfig.menuItems.map((item, idx) => (
            <li key={idx} className="relative hover:text-gray-900 cursor-pointer" >
              {item.dropdown ? (
                <>
                  <span
                    onClick={() => {
                      toggleDropdown(item.name.toLowerCase());
                      handleMenuClick(item.name);
                    }}
                    className={`flex items-center justify-center ${activeMenu === item.name ? "underline font-semibold" : ""
                      }`}
                  >
                    {item.name}
                    <img
                      src={downdrop}
                      alt="Dropdown Icon"
                      className={`ml-1 mt-1 w-4 h-4 transform transition-transform duration-300 ${dropdownState === item.name.toLowerCase() ? "rotate-180" : ""
                        }`}
                    />

                  </span>
                  {renderDropdown1(
                    item.dropdown,
                    dropdownState === item.name.toLowerCase()
                  )}
                </>
              ) : (
                <a
                  href={item.link}
                  onClick={() => handleMenuClick(item.name)}
                  className={`${activeMenu === item.name ? "underline font-semibold" : ""
                    }`}
                >
                  {item.name}
                </a>
              )}
            </li>
          ))}

          {/* Small Screen Buttons */}
          <div className="flex flex-col items-center justify-center space-y-2 mt-4 w-full">
            <button className="bg-[#02AAE2] text-white jakarta px-4 py-2 rounded-md text-sm w-full text-center" style={{ border: '1px solid black' }}>
              <a href="/contactus">Get in touch</a>
            </button>
            <button className="border bg-white jakarta border-gray-700 text-gray-700 px-4 py-2 rounded-md text-sm w-full text-center" style={{ border: '1px solid black' }}>
              <a href="#sign-in">Sign in</a>
            </button>
          </div>
          <p className="text-sm jakarta lg:text-base mt-4 text-gray-500 lg:hidden md:hidden block">
            © 2023 DataEvolve.<br />
            All rights reserved.
          </p>
          <div className="flex mt-5 space-x-4 md:hidden lg:hidden">
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src={Twitter} alt="Twitter" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src={Facebook} alt="Facebook" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
              <img src={Internet} alt="Website" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <img src={Insta} alt="Instagram" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
            <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
              <img src={Youtube} alt="YouTube" className="w-3 h-3 lg:w-5 lg:h-5" />
            </a>
          </div>

        </ul>
      </div>

    </nav>
  );
};

export default Navbar;
