import React from 'react';
import test from '../assets/homeimg/test.webp';
import Test1 from '../assets/test1.webp';

const Testimonials = () => {


    const blogs13 = [
        {
            content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before." ,
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!" ,
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent." ,
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!" ,
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        {
            content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team." ,
            author: "ABC",
            role: "Web Designer",
            images: Test1,
        },
        
    ];

    const blogs12 = [
        {
            content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before." ,
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!" ,
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent." ,
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
        {
            content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!" ,
            author: "ABC",
            role: "Web Designer",
            image: Test1,
        },
        {
            content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team." ,
            author: "ABC",
            role: "Web Designer",
            image: test,
        },
       
    ];

    return (
        <div className="text-center mt-6 mb-8 pb-5">
            <h3 className="text-4xl rubik font-bold" style={{ color: "#000000" }}>
                Testimonials
            </h3>
            <h3 className="jakarta mt-2" style={{ color: "#222222" }}>
                The proof is in their AWS bills
            </h3>


            <div className="overflow-hidden mt-5 ">
                <div className="animate-scroll-left-to-right mt-8">
                    <div
                        className=" flex gap-4"
                    // style={{
                    //     display: "flex",
                    //     animation: "scroll 60s linear infinite",
                    // }}
                    >
                        {blogs13.map((blog, index) => (
                            <div flex flex-col
                                key={index}
                                className="w-[280px] h-[280px] lg:w-[90%] lg:h-[300px] md:w-[70%] md:h-[280px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                                style={{
                                    flex: "0 0 auto",
                                    backgroundColor: "#fff",
                                    border: "1px solid black",
                                }}
                            >
                                {/* Left Column: Text Content */}
                                <div className="flex flex-col justify-between p-0">
                                    <p
                                        className=" text-lg  md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                                        style={{
                                            wordWrap: "break-word", // Forces long words to break
                                            overflowWrap: "break-word", // Ensures wrapped text fits in the box
                                        }}
                                    >
                                        {blog.content}
                                    </p>
                                    {/* left Column: Images */}
                                    <div className='flex'>
                                        <div className="flex justify-start items-center md:hidden lg:hidden">
                                            <img
                                                src={blog.images}
                                                alt={blog.author}
                                                className="w-24 h-24 ml-4  object-contain block sm:hidden"
                                            />
                                        </div>

                                        <h3
                                            className=" flex justify-start ml-12 mt-5  md:justify-end lg:justify-end rubik font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base  lg:ml-4 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5"
                                            style={{ color: "#222222" }}
                                        >
                                            - {blog.author}, {blog.role}
                                        </h3>
                                    </div>
                                </div>

                                {/* Right Column: Images */}
                                <div className="flex justify-end items-center">
                                    <img
                                        src={blog.images}
                                        alt={blog.author}
                                        className="w-64 h-64 hidden md:flex lg:flex"
                                    />
                                </div>


                            </div>
                        ))}
                        {/* Duplicating the content to create the loop effect */}
                    </div>
                </div>





                <div className="overflow-hidden mt-5">
                    <div className="overflow-hidden mt-10">
                        <div
                            className="flex animate-infinite-scroll gap-4"
                            style={{
                                display: "flex",
                            }}
                        >
                            {blogs12.map((blog, index) => (
                                <div
                                    key={index}
                                    className="w-[280px] h-[280px] lg:w-[90%] lg:h-[300px] md:w-[70%] md:h-[260px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                                    style={{
                                        flex: "0 0 auto",
                                        backgroundColor: "#ffff",
                                        border: "1px solid black",
                                    }}
                                >
                                    {/* Left Column: Text Content */}
                                    <div className="flex flex-col justify-between p-1">
                                        <p
                                            className="text-lg  md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                                            style={{
                                                wordWrap: "break-word",
                                                overflowWrap: "break-word",
                                            }}
                                        >
                                            {blog.content}
                                        </p>
                                        {/* Left Column: Images */}
                                        <div className="flex">
                                            <div className="flex justify-start items-center md:hidden lg:hidden">
                                                <img
                                                    src={blog.image}
                                                    alt={blog.author}
                                                    className="w-24 h-24 ml-4 object-contain block sm:hidden"
                                                />
                                            </div>

                                            <div>
                                                <h3
                                                    className="flex justify-start ml-12 mt-5  md:justify-end lg:justify-end rubik font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base lg:ml-2 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5"
                                                    style={{ color: "#222222" }}
                                                >
                                                    - {blog.author}, {blog.role}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex justify-end items-center">
                                        <img
                                            src={blog.image}
                                            alt={blog.author}
                                            className="w-64 h-64 hidden md:flex lg:flex"
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>






                <div className="animate-scroll-left-to-right mt-10">
                    <div
                        className=" flex gap-4"
                    // style={{
                    //     display: "flex",
                    //     animation: "scroll 60s linear infinite",
                    // }}
                    >
                        {blogs13.map((blog, index) => (
                            <div flex flex-col
                                key={index}
                                className="w-[280px] h-[280px] lg:w-[90%] lg:h-[300px] md:w-[70%] md:h-[280px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                                style={{
                                    flex: "0 0 auto",
                                    backgroundColor: "#fff",
                                    border: "1px solid black",
                                }}
                            >
                                {/* Left Column: Text Content */}
                                <div className="flex flex-col justify-between p-0">
                                    <p
                                        className=" text-lg  md:text-lg lg:text-xl mb-4 text-left rubik font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                                        style={{
                                            wordWrap: "break-word", // Forces long words to break
                                            overflowWrap: "break-word", // Ensures wrapped text fits in the box
                                        }}
                                    >
                                        {blog.content}
                                    </p>
                                    {/* left Column: Images */}
                                    <div className='flex'>
                                        <div className="flex justify-start items-center md:hidden lg:hidden">
                                            <img
                                                src={blog.images}
                                                alt={blog.author}
                                                className="w-24 h-24 ml-4  object-contain block sm:hidden"
                                            />
                                        </div>

                                        <h3
                                            className=" flex justify-start ml-12 mt-5  md:justify-end lg:justify-end jakarta font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base  lg:ml-4 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5"
                                            style={{ color: "#222222" }}
                                        >
                                            - {blog.author}, {blog.role}
                                        </h3>
                                    </div>
                                </div>

                                {/* Right Column: Images */}
                                <div className="flex justify-end items-center">
                                    <img
                                        src={blog.images}
                                        alt={blog.author}
                                        className="w-64 h-64 hidden md:flex lg:flex"
                                    />
                                </div>


                            </div>
                        ))}
                        {/* Duplicating the content to create the loop effect */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;
