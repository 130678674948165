// api.Common.jsx
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  GoogleAPI,
  LoginAPI,
  MicrosoftAPI,
  SignupAPI,
  GithubAPI,
} from '../API/api';

const axiosInstance = axios.create();
const isOnline = () => window.navigator.onLine;

const excludedAPIs = [GoogleAPI, LoginAPI, MicrosoftAPI, SignupAPI, GithubAPI];

axiosInstance.interceptors.request.use(
  (config) => {
    const isExcluded = excludedAPIs.some((api) => config.url.includes(api));

    if (isExcluded) return config;

    const userDetails = JSON.parse(localStorage.getItem('UserDetails'));

    if (
      config.url.includes('user_login') ||
      config.url.includes('user_logoutalldevices')
    ) {
      return config;
    }

    if (userDetails) {
      config.headers['Authorization'] = `Bearer ${userDetails.accessToken}`;
      config.headers['id_token'] = JSON.stringify(userDetails.idToken);
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => {
    // Handle successful response
    if ([405, 401].includes(response.data.code)) {
      toast.error(response.data.message);
      // window.alert(response.data.message);
      localStorage.clear(); // Clear all relevant user info
      setTimeout(() => {
        // window.location.href = '/auth/sign-in';
      }, 3000);
    }
    if ([406].includes(response.data.code)) {
      toast.error(response.data.message);
    }
    return response;
  },
  (error) => {
    if (!isOnline()) {
      toast.error('Please check your internet connection');
    } else if (error.response) {
      const status = error.response.status;
      const { code, message } = error.response.data || {};

      // Handle 401, 405, 406 errors based on status code
      if (status === 401 || [405].includes(code)) {
        toast.error(message || 'Unauthorized! Redirecting to sign-in...');
        localStorage.clear(); // Clear all user info
        // window.alert(message);
        setTimeout(() => {
          // window.location.href = '/auth/sign-in';
        }, 3000);
      } else if ([500, 203].includes(code)) {
        toast.error(message || 'An unexpected error occurred');
      }
    }
    return Promise.reject(error);
  }
);

// Request methods
export const get = (url, config = {}) => axiosInstance.get(url, config);
export const post = (url, payload, config = {}) =>
  axiosInstance.post(url, payload, config);
