import React from 'react';
import Logo from '../assets/logo.webp';
import Twitter from '../assets/twitter.webp';
import Facebook from '../assets/facebook.webp';
import Internet from '../assets/internet.webp';
import Insta from '../assets/insta.webp';
import Youtube from '../assets/youtube.webp';
import Cloudevolve from '../assets/cloudevolve.webp';
import Base from '../assets/Base.webp';
import { useNavigate } from "react-router-dom";



const Footer = () => {
    const navigate = useNavigate();
    return (
        <div className="bg-white w-full min-h-screen flex flex-col items-center ">
            {/* Box-shadowed Horizontal Line */}
            <div className="w-full h-0.5 bg-gray-100 shadow-lg mt-[10px] md:mt-[70px] lg:mt-[100px]"></div>

            <div className="flex flex-col lg:flex-row  md:flex-row lg:justify-between  md:justify-around   lg:mt-20 w-full px-4 pt-10 lg:w-[60rem]">
                <div className="flex flex-col lg:items-start md:items-start text-left   mb-8 lg:mb-0 lg:mr-20 items-center">
                    <div className="w-44 lg:w-52 md:w-52 h-auto ">
                        <img src={Logo} alt="Check badge" />
                    </div>
                    <p className="text-sm jakarta lg:text-base mt-4 text-gray-500 hidden lg:block md:block ">
                        © 2025 Cloudevolve Inc<br />
                        All rights reserved.
                    </p>

                    <div className="flex mt-5 space-x-4 lg:flex md:flex hidden">
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <img src={Twitter} alt="Twitter" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={Facebook} alt="Facebook" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                            <img src={Internet} alt="Website" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={Insta} alt="Instagram" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <img src={Youtube} alt="YouTube" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                    </div>


                </div>




                <div className="flex flex-col mb-6 lg:items-start md:items-start items-center md:hidden lg:hidden">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Explore more</h2>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Aboutus");
                                window.scrollTo(0, 0);
                            }}
                        >
                            About Us
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/Cloud360");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Cloud 360
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/warehouse");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Savings warehouse
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/Cloudmanagedservices");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Services
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Pricing");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Pricing
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Documentation");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Documentation
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">Terms of Use</p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">Privacy Policy</p>
                </div>




                <div className="flex flex-col text-left mb-8 lg:mb-0 lg:mr-14 lg:items-start md:items-start items-center">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Get in Touch</h2>
                    <p className="text-sm jakarta lg:text-base mt-2 text-gray-500">16192, Coastal Highway,</p>
                    <p className="text-sm jakarta lg:text-base text-gray-500">Lewes, Delaware, 19958-3608,</p>
                    <p className="text-sm jakarta lg:text-base text-gray-500">United States</p>

                    <a href="mailto:info@cloudevolve.com" className="text-sm jakarta lg:text-base mt-2 text-gray-500 hover:underline">
                        info@cloudevolve.com
                    </a>
                    <a href="tel:+16507033689" className="text-sm jakarta lg:text-base text-gray-500 hover:underline">
                        +16507033689
                    </a>
                </div>


                <div className="flex flex-col lg:mr-44 lg:items-start md:items-start items-center hidden lg:block md:block">
                    <h2 className="text-xl rubik lg:text-xl font-semibold text-black">Explore more</h2>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Aboutus");
                                window.scrollTo(0, 0);
                            }}
                        >
                            About Us
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/Cloud360");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Cloud 360
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/warehouse");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Savings warehouse
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/platform/Cloudmanagedservices");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Services
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Pricing");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Pricing
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">
                        <button
                            onClick={() => {
                                navigate("/Documentation");
                                window.scrollTo(0, 0);
                            }}
                        >
                            Documentation
                        </button>
                    </p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">Terms of Use</p>
                    <p className="text-sm jakarta lg:text-base text-gray-500 mt-2">Privacy Policy</p>
                </div>

                <div className="flex flex-col lg:items-start md:items-start items-center">
                    <p className="text-sm jakarta lg:text-base mt-4 text-gray-500 lg:hidden md:hidden block">
                        © 2025 Cloudevolve Inc<br />
                        All rights reserved.
                    </p>
                    <div className="flex mt-5 space-x-4 md:hidden lg:hidden">
                        <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                            <img src={Twitter} alt="Twitter" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                            <img src={Facebook} alt="Facebook" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                            <img src={Internet} alt="Website" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                            <img src={Insta} alt="Instagram" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                        <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer">
                            <img src={Youtube} alt="YouTube" className="w-3 h-3 lg:w-5 lg:h-5" />
                        </a>
                    </div>
                </div>

            </div>

            <div className="relative w-full mt-8">
                {/* Centered, scalable image */}
                <div className="flex items-center justify-center">
                    <img
                        src={Cloudevolve}
                        alt="Vector"
                        className="mt-[26px] w-[260px] sm:w-[305px] sm:mt-6 md:mt-2 md:w-full lg:mt-4 lg:w-full h-auto"
                    />
                </div>


                {/* Full-width base image */}
                <div className="absolute top-10 left-0 w-full">
                    <img
                        src={Base}
                        alt="Base Vector"
                        className="w-full h-auto"
                    />
                </div>
            </div>

        </div>





    )
};
export default Footer;