import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Warehouse from './Platform/warehouse';
import Home from './Homepage/Home';
import PricingPage from './Pricing/pricing';
import ContactForm from './Contactus/Contactus';
import Aboutus from './Aboutus/Aboutus';
import Cloud360 from './Cloud360/Cloud360';
import Cloudmanagedservices from './cloudmanagedservices/Cloudmanagedservices';
import Footer from './Footer/footer';

import Navbar from './Common/Navbar';
import Bookyourdate from './Common/Bookyourdate';
import Testimonials from './Testimonials/Testimonials';
import Blogs from './Resources/Blogs';
import Faq from './Resources/Faq';



const App = () => {
  return (
    <Router>
      <nav>
        <Navbar />
      </nav>
      <Routes>
        <Route path="/" element={<Navigate to="/home" replace />} />
        <Route path="/platform/warehouse" element={<Warehouse />} />
        <Route path="/home" element={<Home />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/contactus" element={<ContactForm />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/platform/cloud360" element={<Cloud360 />} />
        <Route path="/platform/cloudmanagedservices" element={<Cloudmanagedservices />} />
        <Route path="/bookyour" element={<Bookyourdate />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Faq" element={<Faq />} />

      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
