import React, { useEffect, useRef, useState } from "react";
import rightsidearrow from '../assets/warehouse/rightsidearrow.webp';
// import firstCardimg from '../assets/warehouse/firstCardimg.webp';
import arrowblc from '../assets/warehouse/arrowblc.webp';

import nosaving from '../assets/warehouse/nosaving.webp';
import upto from '../assets/warehouse/upto.webp';
import cloudany from '../assets/warehouse/cloudany.webp';
import cloudev from '../assets/warehouse/cloudev.webp';
import homeCurve from '../assets/homeimg/homeCurve.webp';
import abc from '../assets/homeimg/abc.webp';
import bbc from '../assets/homeimg/bbc.webp';
import black from '../assets/homeimg/black.webp';
import gateway from '../assets/homeimg/gateway.webp';
import jack from '../assets/homeimg/jack.webp';
import sugar from '../assets/homeimg/sugar.webp';
import wwf from '../assets/homeimg/wwf.webp';
import xbox from '../assets/homeimg/xbox.webp';
import sea from '../assets/cloud bills.webp';
import sec from '../assets/aws price.webp';
import third from '../assets/cloud cost.webp';
import uparrowicon from '../assets/homeimg/uparrowicon.webp';
//import cld from '../assets/homeimg/cld.webp';
// import swarehouse from '../assets/homeimg/swarehouse.webp';
// import savingCo from '../assets/homeimg/savingCo.webp';
// import secure from '../assets/secure.webp';
import blg from '../assets/homeimg/blg.webp';
import test from '../assets/homeimg/test.webp';
import Test1 from '../assets/test1.webp';
// import price from '../assets/homeimg/price.webp';
// import prc2 from '../assets/homeimg/prc2.webp';
// import huge from '../assets/homeimg/huge.webp';
import checkCircleIcon from "../assets/Icon.webp";
import Vector624 from '../assets/Vector 624.webp';
import plus from '../assets/homeimg/plus.webp'
import xmark from '../assets/homeimg/xmark.webp'
import { useNavigate } from "react-router-dom";

import Icon1 from '../assets/Icon1.webp';
import Group1 from '../assets/Group1.webp';
// import Hand from "../assets/hand.webp";
import Cloud from '../assets/cloud.webp';
import Group2 from "../assets/group2.webp";
import data from '../assets/homeimg/data.json';
import Lottie from 'lottie-react';
import Coming from "../assets/coming.mp4";
import Secure from "../assets/secure2.mp4";
import Hero from "../assets/home.mp4";
import Savings from "../assets/savings3.mp4";
import Hand from "../assets/get started.gif";

const Home = () => {
  const scrollRef = useRef(null);
  const [openIndex, setOpenIndex] = useState(null);
  const toggleFaq = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const faqs = [
    {
      question: "Are there any commitments required?",
      answer: "No, we believe in flexibility! You can enjoy savings without any long-term commitments.",
      //tags: ["Cloud cost", "AWS"],
    },
    {
      question: "Is there any fee to join?",
      answer: "No, there is no fee to join.",
      //tags: ["GCP", "Kubernetes"],
    },
    {
      question: "What makes Cloudevolve different from other platforms?",
      answer: "Cloudevolve offers competitive pricing and seamless integration.",
      //tags: ["Snowflake", "Cloud cost"],
    },
    {
      question: "How often does pricing adjust to usage changes?",
      answer: "Pricing is adjusted based on your usage every month.",
      //tags: ["AWS", "Kubernetes"],
    },
    {
      question: "Can I access detailed reports on my spending?",
      answer: "Yes, you can access detailed spending reports through the dashboard.",
      //tags: ["GCP", "Snowflake"],
    },
    {
      question: "What happens if my cloud usage changes?",
      answer: "Your pricing adjusts automatically to reflect your current usage.",
      //tags: ["Cloud cost"],
    },
    {
      question: "Are there any hidden fees?",
      answer: "No, we maintain complete transparency with our pricing.",
      tags: ["AWS", "Snowflake"],
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      if (scrollRef.current) {
        const container = scrollRef.current;

        // Scroll to the next item
        container.scrollBy({
          left: container.offsetWidth,
          behavior: "smooth",
        });

        // If it's the end, reset to the start
        if (
          container.scrollLeft + container.offsetWidth >=
          container.scrollWidth
        ) {
          setTimeout(() => {
            container.scrollTo({ left: 0, behavior: "smooth" });
          }, 3000); // Add delay before resetting
        }
      }
    }, 3000); // Scroll every 3 seconds

    return () => clearInterval(interval);
  }, []);




  const navigate = useNavigate();

  const cardData = [
    {
      title: "No Savings Plan",
      description: "Save on cloud costs,\nno commitments needed.",
      icon: nosaving,
      alt: "Discount Icon",
    },
    {
      title: "Up to 60% Discounts",
      description: "Slash your cloud costs by up to 60%,\nno long-term deals required.",
      icon: upto,
      alt: "No Commitment Icon",
    },
    {
      title: "Cloud Analytics",
      description: "Stay a step ahead with real-time insights\ntailored to your cloud’s performance.",
      icon: cloudany,
      alt: "Cost Transparency Icon",
    },
    {
      title: "Secured",
      description: "Fortify your cloud with security that’s\nalways on guard.",
      icon: cloudev,
      alt: "Personalized Savings Icon",
    },
  ];


  const blogs = [
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["Technology", "Technology"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["Technology", "Technology"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["Technology", "Technology"],
      image: third, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["Technology", "Technology"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["Technology", "Technology"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["Technology", "Technology"],
      image: third, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "How Cloudevolve Slashes 60% Off Your Cloud Bills ",
      tags: ["Technology", "Technology"],
      image: sea, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "AWS Price Hikes in 2024? How to Prepare and Protect Your Budget ",
      tags: ["Technology", "Technology"],
      image: sec, // Replace with actual image path
    },
    {
      date: "23/04/24",
      title: "Cloud Cost Optimization in 2024: Trends, Insights, and How to Stay Ahead",
      tags: ["Technology", "Technology"],
      image: third, // Replace with actual image path
    },

  ];
  const blogs12 = [
    {
      content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content: "Our AWS bills were skyrocketing until we discovered Cloudevolve. Their platform cut our costs significantly without any upfront commitments. Truly a game-changer for our business!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content: "Cloudevolve makes cloud savings effortless. We saved over 50% on our cloud expenses, and their insights helped us optimize resource utilization like never before.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "What sets Cloudevolve apart is their risk-free approach to savings. We saw tangible benefits within weeks!",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },
    {
      content: "Their Cloud 360 Intelligence Platform provided unparalleled visibility into our AWS spending. The detailed analytics helped us stay on top of every dollar spent.",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "From cost optimization to real-time insights, Cloudevolve has become an indispensable partner in our cloud journey. Highly recommended for any AWS user!",
      author: "ABC",
      role: "Web Designer",
      image: Test1,
    },
    {
      content: "Joining Cloudevolve was the best decision for our cloud management strategy. It's like having a personal cloud savings expert on your team.",
      author: "ABC",
      role: "Web Designer",
      image: test,
    },

  ];
  const problemCards = [
    {
      icon: nosaving,
      title: "Do I need to buy Savings Plan in my Account?",
      alt: "Over-Provisioning Icon",
      solution: "No, we offer savings without purchasing Savings Plans.",
    },
    {
      icon: cloudany,
      title: "Reduce my Cloud cost up to 60%?",
      alt: "Data Transfer Icon",
      solution: "Easy! Instant discounts, no commitments",
    },
    {
      icon: cloudev,
      title: "Unpredictable AWS Bills?",
      alt: "Spikes in Demand Icon",
      solution: "Track and optimize costs with Spend Insight.",
    },
    {
      icon: upto,
      title: "Worried About EC2 Right-Sizing?",
      alt: "Cloud Billing Icon",
      solution: "Automate scaling with Cloudevolve.",
    },
    {
      icon: nosaving,
      title: "Paying for Idle Resources?",
      alt: "Over-Provisioning Icon",
      solution: "Tag Orbit identifies and eliminates waste.",
    },


    {
      icon: upto,
      title: "High Data Transfer Costs?",
      alt: "Data Transfer Icon",
      solution: "Reduce expenses with Savings Warehouse discounts.",
    },
    {
      icon: cloudany,
      title: "Can’t Set a Cloud Budget?",
      alt: "Spikes in Demand Icon",
      solution: "Monitor and control spend using Spend Tracker.",
    },
    {
      icon: cloudev,
      title: "Is Your Workload Secure?",
      alt: "Spikes in Demand Icon",
      solution: "Ensure compliance with Cloud Patrol’s checks.",
    },
    {
      icon: upto,
      title: "Skyrocketing S3 Costs?",
      alt: "Cloud Billing Icon",
      solution: "Optimize storage tiers with Savings Copilot.",
    },
    {
      icon: cloudev,
      title: "Overprovisioning Resources?",
      alt: "Spikes in Demand Icon",
      solution: "Optimize workloads efficiently with Rightsizing Resources.",
    },
    {
      icon: upto,
      title: "Surprised by Hidden Charges?",
      alt: "Cloud Billing Icon",
      solution: "Gain full visibility with Cloud 360’s cost insights.",
    },
    {
      icon: cloudev,
      title: "Inefficient Workload Performance?",
      alt: "Spikes in Demand Icon",
      solution: "Fine-tune operations with Cloudevolve",
    },
    {
      icon: upto,
      title: "Confused About Savings Plans?",
      alt: "Cloud Billing Icon",
      solution: "Skip commitments with instant Savings Warehouse deals.",
    },
    {
      icon: cloudev,
      title: "Can’t Manage Complex Multi-Tagging?",
      alt: "Spikes in Demand Icon",
      solution: "Streamline with Tag Orbit’s automation.",
    },
    {
      icon: upto,
      title: "Is Your VPC Setup Cost-Effective?",
      alt: "Cloud Billing Icon",
      solution: "Get optimized network recommendations with Cloud Patrol.",
    },
  ];

  return (
    < >
      <div className="text-center bg-white mt-1 pt-1 pb-1">
        <div className="relative flex flex-col justify-center items-center">
          {/* Video as background */}
          <video
            src={Hero}
            autoPlay
            loop
            muted
            className="absolute top-0 left-0 w-full object-cover z-1 pointer-events-none"
          />

          {/* Content on top of the video */}
          <div className="relative z-10 text-center">
            <h2
              className="rubik text-xs md:text-xl lg:text-5xl mt-8 lg:mt-52 font-bold text-center"
              style={{ color: "#000000" }}
            >
              Jet. Set. Free
            </h2>
            <h2
              className="mt-0 md:mt-5 lg:mt-1 text-xs md:text-xl lg:text-xl w-[100%] md:w-[75%] lg:w-[70%] mx-auto text-center jakarta"
              style={{ color: "#222222" }}
            >
              Discover ways to optimise your AWS costs without purchasing savings plan or
              making any reservations at your end.
            </h2>
            <div className="flex justify-center items-center mt-[110px]">
              {/* Button container */}
              <div
                className="text-white jakarta px-4 py-3 rounded-lg font-semibold flex items-center gap-3 cursor-pointer transition"
                onClick={() => navigate("/bookyour")}
              >
                <span className="rainbow-effect animate-blink">Schedule 1:1</span>
                <img src={rightsidearrow} className="w-5 h-5 rainbow-effect animate-blink" alt="arrow" />
              </div>
            </div>

          </div>
        </div>
      </div>



      <h1 className="flex justify-center items-center mt-72 rubik font-bold ">The client Crew</h1>
      {/* First Div - Visible only on larger screens */}
      <div className="mt-8 hidden md:block"> {/* Hidden on small screens, visible on medium and larger screens */}
        <div className="flex flex-wrap justify-center gap-5 items-center">
          <img
            src={xbox}
            alt="XBOX Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={black}
            alt="Black Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={bbc}
            alt="BBC Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={abc}
            alt="ABC Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={gateway}
            alt="Gateway Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={jack}
            alt="Jack Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={wwf}
            alt="WWF Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={sugar}
            alt="Sugar Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={abc}
            alt="ABC Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={gateway}
            alt="Gateway Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>

        <br /> {/* Line Break */}

        <div className="flex flex-wrap justify-center gap-5 items-center">
          <img
            src={gateway}
            alt="Gateway Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={jack}
            alt="Jack Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={wwf}
            alt="WWF Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={abc}
            alt="ABC Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={gateway}
            alt="Gateway Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={sugar}
            alt="Sugar Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
          <img
            src={xbox}
            alt="XBOX Logo"
            className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
          />
        </div>
      </div>

      {/* Second Div - Scrolling, visible only on mobile screens */}
      <div className="overflow-hidden mt-10 block md:hidden"> {/* Hidden on medium and larger screens, visible on small screens */}
        <div className="flex gap-4 animate-scroll items-center">
          {[xbox, black, bbc, abc, gateway, jack, wwf, sugar, abc, gateway, gateway, jack, wwf, abc, sugar, xbox].map(
            (logo, index) => (
              <img
                key={`logo-${index}`}
                src={logo}
                alt={`Logo ${index + 1}`}
                className="w-[63px] h-[55px] grayscale hover:grayscale-0 transition-all duration-300"
              />
            )
          )}
        </div>
      </div>



      {/* Reduced margin above the home curve */}
      <div className="flex justify-center mt-0 mb-50">
        <img src={homeCurve} alt="homecurve" />
      </div>
      <div className="text-center mt-20 pb-11" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center " style={{ color: "#000000" }}>
          Problems you face
        </h3>
        <h3 className="mt-2 jakarta" style={{ color: "#222222" }}>
          Because every cloud problem deserves a Cloudevolve solution.
        </h3>

        {/* Scrolling Container */}
        <div className="overflow-hidden mt-10 parent-container">
          {/* Row 1: Left to Right */}
          <div className="flex gap-4 animate-scroll mb-4">
            {problemCards.map((problem, index) => (
              <div key={`row1-${index}`} className="flip-card">
                <div className="flip-card-inner">
                  {/* Front Side */}
                  <div
                    className="flip-card-front flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md"
                    style={{
                      width: "260px",
                      height: "67px", // Front side height
                      flexShrink: 0,
                    }}
                  >
                    <div className="h-8 w-8 flex items-center justify-center rounded">
                      <img src={problem.icon} alt={problem.alt} className="w-10 h-6 object-contain" />
                    </div>
                    <span className="ml-3 text-sm jakarta" style={{ color: "#222222" }}>
                      {problem.title}
                    </span>
                  </div>
                  {/* Back Side */}
                  <div
                    className="flip-card-back flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md"
                    style={{
                      width: "260px",
                      height: "67px", // Match the front side height
                      flexShrink: 0,
                    }}
                  >
                    <div className="h-8 w-8 flex items-center justify-center rounded">
                      <img src={problem.icon} alt={problem.alt} className="w-10 h-6 object-contain" />
                    </div>
                    <span className="ml-3 text-sm leading-relaxed jakarta" style={{ color: "#222222" }}>
                      {problem.solution}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Row 2: Left to Right */}
          <div className="flex gap-4 animate-scroll-left-to-right mb-2">
            {problemCards.map((problem, index) => (
              <div key={`row2-${index}`} className="flip-card">
                <div className="flip-card-inner">
                  {/* Front Side */}
                  <div
                    className="flip-card-front flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md"
                    style={{
                      width: "260px",
                      height: "67px", // Front side height
                      flexShrink: 0,
                    }}
                  >
                    <div className="h-8 w-8 flex items-center justify-center rounded">
                      <img src={problem.icon} alt={problem.alt} className="w-10 h-6 object-contain" />
                    </div>
                    <span className="ml-3 text-sm jakarta" style={{ color: "#222222" }}>
                      {problem.title}
                    </span>
                  </div>
                  {/* Back Side */}
                  <div
                    className="flip-card-back flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md"
                    style={{
                      width: "260px",
                      height: "67px", // Match the front side height
                      flexShrink: 0,
                    }}
                  >
                    <div className="h-8 w-8 flex items-center justify-center rounded">
                      <img src={problem.icon} alt={problem.alt} className="w-10 h-6 object-contain" />
                    </div>
                    <span className="ml-3 text-sm leading-relaxed jakarta" style={{ color: "#222222" }}>
                      {problem.solution}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>




      {/* Row 2 */}
      {/* <div
            className="flex gap-6 mt-5 mb-4 animate-scroll"
            style={{
              animationDelay: "1.5s", // Add delay for staggered scrolling
            }}
          >
            {problemCards.map((problem, index) => (
              <div
                key={`row2-${index}`}
                className="flex items-center text-left px-4 py-2 bg-white rounded-lg shadow-md"
                style={{
                  width: "260px",
                  flexShrink: 0,
                }}
              >
                <div className="h-8 w-8 flex items-center justify-center rounded">
                  <img
                    src={problem.icon}
                    alt={problem.alt}
                    className="w-10 h-6 object-contain"
                  />
                </div>
                <span
                  className="ml-3 text-sm font-semibold"
                  style={{ color: "#222222" }}
                >
                  {problem.title}
                </span>
              </div>
            ))}
          </div> */}
      {/* Benefits Section */}
      <div className="text-center mt-[70px] mb-[120px] pb-5" >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Why CloudEvolve ?
        </h3>
        < h3 className="font-normal mt-2 jakarta" style={{ color: "#222222" }}>
          One platform that puts cloud savings in your hands
        </h3>

        < div className="relative flex flex-col justify-center items-center" >
          {/* Horizontal Line */}
          < div className="absolute lg:top-56 lg:ml-48 md:top-56 md:ml-12 lg:left-0 md:left-0 lg:w-[700px] md:w-[700px] xl:ml-80 lg:h-0.5 md:h-0.5 bg-gray-25 transform -translate-y-1/4" > </div>
          {/* Vertical Line */}
          <div className="absolute lg:left-1/2 md:left-1/2 lg:top-10 md:top-5 lg:h-[410px] md:h-[420px] w-0.5 bg-gray-25" > </div>
          < div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-40 gap-y-8 mt-12 px-4 lg:px-40" >
            {
              cardData.map((card, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center text-center w-full"
                >
                  <div className="h-16 w-16 flex items-center justify-center rounded" >
                    <img src={card.icon} alt={card.alt} className="w-14 h-14" />
                  </div>
                  <h4 className="text-lg font-medium mt-4 font-rubik" > {card.title} </h4>
                  < p
                    className="text-sm text-gray-600 jakarta mt-2"
                    style={{ color: "#222222", maxWidth: '280px' }}
                  >
                    {card.description}
                  </p>
                </div>
              ))}
          </div>
        </div>


      </div>

      {/* Light-colored straight line box shadow */}
      <div
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Light, subtle shadow
          height: "1px", // Thin straight line
          width: "100%", // Full-width line
          backgroundColor: "#ffffff", // Match background color
          marginTop: "-50px", // Optional: adjust if needed
        }}
      ></div>


      <div className="text-center mt-[70px] pb-5" >

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">

          <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>

            Platform

          </h3>

          <h3 className="mt-2 mb-6 jakarta" style={{ color: "#222222" }}>

            Welcome to a smarter way to save and manage your AWS spend

          </h3>



          {/* Cards Container */}
          <div className="flex-col sm:flex-col lg:flex-row md:flex-row gap-6 text-left">
            {/* First Card - Increased width for Cloud 360 */}
            <div className="bg-white lg:col-span-6 flex justify-center">
              <div className="p-6 grid grid-row-2 md:grid-cols-2 lg:grid-cols-2 items-center rounded-2xl border border-black lg:w-[890px]"> {/* Adjust width as necessary */}
                {/* Text Content for Cloud 360 */}
                <div>
                  <h2 className="text-xl font-bold mb-4 font-rubik">Cloud 360</h2>
                  <p className="mb-4 jakarta">
                    Gain 360-degree visibility of your Cloud. Your all-in-one intelligence
                    platform for complete AWS cost visibility, real-time analytics, and
                    seamless tagging. Gain control, optimize spend, and make smarter cloud
                    decisions effortlessly.
                  </p>
                  <div className="flex flex-wrap gap-2 mb-4 jakarta">
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Cloud Analytics</span>
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Budget Alert</span>
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Tagger</span>
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Conformity</span>
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Stop Idle Resources</span>
                    <span className="bg-[#E1F5FC] text-sm py-1 px-3 rounded">Cost Fixer</span>
                  </div>
                  <button
                    className="bg-white border pl-2 pr-1 py-2 jakarta text-sm rounded-lg flex items-center gap-1 hover:text-blue-500"
                    onClick={() => navigate("/platform/Cloud360")}
                  >
                    Learn more
                    <img src={uparrowicon} alt="Arrow Icon" className="w-5 h-5" />
                  </button>
                </div>
                {/* Image Content for Cloud 360 */}
                <div className="w-full lg:w-auto flex justify-center lg:justify-end mt-4 lg:mt-0">
                  {/* <img
                    src={cld}
                    alt="Service Icon"
                    className="w-full h-[350px] mx-auto object-contain" // Reduced height
                  /> */}
                  <Lottie
                    animationData={data}
                    className="w-auto h-auto object-contain"
                    aria-labelledby="use lottie animation"
                  />
                </div>
              </div>
            </div>

            {/* Cards for Savings Warehouse and Savings Co-Pilot with equal widths */}

            <div className="flex flex-col gap-6 lg:flex-row md:flex-row justify-center items-center gap-3 mt-6 sm:ml-0">
              {/* Savings Warehouse */}
              <div className="bg-white flex justify-center">
                <div
                  className="rounded-xl border border-black pt-6 pl-6 pr-6 sm:w-[350px] lg:w-[430px] lg:h-[567px] md:w-[350px] md:h-[600px] flex flex-col justify-between"
                >

                  <div>
                    <h2 className="text-xl font-bold mb-4 font-rubik">Savings Warehouse</h2>
                    <p className="mb-4 jakarta">
                      No hassle of purchasing Savings plans, giving a commitment to AWS. You
                      get direct discounts without giving a commitment of consumption.
                    </p>

                    <div className="flex flex-wrap gap-2 mb-4 jakarta">
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">EC2</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">SageMaker</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">Redshift</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">S3</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">EC2 Data Transfer</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">ECS</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">RDS</span>
                      <span className="bg-[#F2EDFE] text-sm py-1 px-3 rounded">OpenSearch</span>
                    </div>
                    <button
                      className="bg-white jakarta border py-2 px-4 rounded-lg text-bold flex items-center gap-2 hover:text-blue-500"
                      onClick={() => navigate("/platform/warehouse")}
                    >
                      Learn more
                      <img src={uparrowicon} alt="Arrow Icon" className="w-5 h-5" />
                    </button>
                  </div>
                  <video
                    src={Savings}
                    autoPlay
                    loop
                    muted
                    className="w-full"
                  />
                </div>
              </div>

              {/* Savings Co-Pilot */}
              <div className="bg-white flex justify-center">
                <div
                  className="rounded-xl border border-black pt-6 pl-6 pr-6 sm:w-[350px]  lg:w-[430px] lg:h-[567px] md:w-[350px] md:h-[600px] flex flex-col justify-between"
                >
                  <h2 className="text-xl font-rubik font-bold mb-4">Savings Co-Pilot</h2>
                  <video
                    src={Coming}
                    autoPlay
                    loop
                    muted
                    className="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="text-center pt-5 mt-14 bg-[#02AAE2] text-[#FFFFFF]">
        <div className="max-w-7xl mx-auto px-4 mt-4 sm:px-6 lg:px-8 text-center">
          {/* <img
            src={price}
            alt="Price Icon"
            className="h-16 w-16 lg:h-16 lg:w-16 lg:ml-44"
          /> */}
          <h3 className="text-4xl font-bold flex justify-center items-center gap-2">
            <span className="rubik text-2xl md:text-4xl lg:text-4xl font-bold leading-[48px] text-center">We are doing it risk-FREE for you...</span>
          </h3>
          <h3 className="mt-5 jakarta mb-6 max-w-xl mx-auto text-center">
            Our goal is to ensure you only pay for what you use, without the burden
            of long-term commitments or hidden fees.
          </h3>

          <div className="flex justify-center mt-5 items-center gap-8">
            <button
              className="bg-white jakarta text-sm px-4 py-2 ml-10 lg:ml-10 md:ml-[40px] rounded-lg font-semibold flex items-center gap-1 shadow-md"
              style={{
                transition: "background-color 0.3s",
                color: "black",
              }}
              onClick={() => navigate("/contactus")}
            >
              <span>Get in touch</span>
              <img src={arrowblc} className="w-5 h-5" alt="Arrow Icon" />
            </button>
            <div className="flex  ">
              {/* <img
                src={prc2}
                alt="Price Icon"
                className="h-10 w-10 lg:h-16 lg:w-16 md:h-16 md:w-16 lg:ml-56 md:ml-44"
              /> */}
            </div>
          </div>

          <section className="mt-6">
            <h2 className="text-center text-xl font-medium mb-8 font-rubik">
              What Makes Our Pricing Stand Out?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
              {/* Feature 1 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="font-rubik font-bold text-lg mt-4 mb-2">
                  No Commitments, <br />
                  No Risk
                </h3>
                <p className="jakarta font-normal">Enjoy savings without long-term contracts or upfront investments.</p>
              </div>
              {/* Feature 2 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="font-rubik font-bold text-lg mt-4 mb-2">
                  Save Up to <br />
                  60% Instantly
                </h3>
                <p className="jakarta font-normal">Reduce your cloud expenses without purchasing savings plans.</p>
              </div>
              {/* Feature 3 */}
              <div className="text-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="font-rubik font-bold text-lg mt-4 mb-2">
                  Direct Discounts on <br />
                  AWS Services
                </h3>
                <p className="jakarta font-normal">Access EC2, RDS, S3, and more with built-in discounts.</p>
              </div>
            </div>

            <br />

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-5 max-w-3xl mx-auto mt-5">
              {/* Feature 4 */}
              <div className="text-center lg:place-self-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="font-rubik font-bold text-lg mt-4 mb-2">
                  Group Buying <br />
                  Power
                </h3>
                <p className="jakarta font-normal">Leverage community-driven savings for<br></br> the best rates.</p>
              </div>
              {/* Feature 5 */}
              <div className="text-center lg:place-self-center">
                <img
                  src={checkCircleIcon}
                  alt="Check Icon"
                  className="h-10 w-10 mx-auto"
                />
                <h3 className="font-rubik font-bold text-lg mt-4 mb-2">
                  Flexible <br />
                  Plans
                </h3>
                <p className="jakarta font-normal">Adapt to changes in cloud consumption<br></br> without penalties.</p>
              </div>
            </div>
          </section>
        </div>

        <div
          className="flex justify-center bg-transparent">
          <img src={Vector624} alt="Curve Background" className="lg:w-full md:w-full w-full " />
        </div>

      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-12 text-left" style={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)" }}>
        {/* Security Section Card */}
        <div className="bg-white lg:col-span-2 flex justify-center lg:mb-12 lg:mt-12">
          <div className="w-full max-w-xl  grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 items-center gap-6">

            {/* Image Content for Security */}
            <div className="order-1 lg:order-1 flex justify-evenly items-center lg:justify-start h-full">
              <video
                src={Secure}
                autoPlay
                loop
                muted
                className="w-full transform lg:translate-x-[-30%]" // Apply the shift only for laptop screens
              />
            </div>



            {/* Text Content for Security */}
            <div className="order-2 lg:order-2 lg:ml-0 ">
              <h2
                className="rubik text-2xl md:text-3xl lg:text-3xl font-bold leading-none text-center lg:text-left whitespace-nowrap"
              >
                How secure are we?
              </h2>
              <p
                className="mb-4 mt-3 jakarta text-center lg:text-left lg:w-[390px] mx-auto"
              >
                From cost management to architectural guidance, we're here to ensure your cloud experience is seamless and optimized.
              </p>


              <div className="flex flex-wrap gap-2 mt-2 mb-12 jakarta justify-center lg:justify-start">
                <div className="flex flex-col lg:flex-row gap-2 justify-center items-center lg:items-start">
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-44 text-center lg:text-left">
                    Continuous Monitoring
                  </span>
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-60 text-center lg:text-left">
                    Automated Threat Responses
                  </span>
                </div>
                <div className="flex flex-col lg:flex-row gap-2 justify-center items-center lg:items-start">
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-40 text-center lg:text-left">
                    Anomaly Detection
                  </span>
                  <span className="bg-[#FFF8F1] text-sm py-1 px-3 rounded w-full lg:w-60 text-center lg:text-left">
                    Safeguard Against Vulnerabilities
                  </span>
                </div>
              </div>
            </div>



          </div>
        </div>
      </div>




      <div
        className="text-center mt-[70px] mb-0 pb-5"

      >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Testimonials
        </h3>
        <h3 className="jakarta mt-2" style={{ color: "#222222" }}>
          The proof is in their AWS bills
        </h3>


        <div className="overflow-hidden mt-5">
          <div className="overflow-hidden mt-10">
            <div
              className="flex animate-infinite-scroll gap-4"
              style={{
                display: "flex",
              }}
            >
              {blogs12.map((blog, index) => (
                <div
                  key={index}
                  className="w-[280px] h-[280px] lg:w-[90%] lg:h-[300px] md:w-[70%] md:h-[300px] max-w-2xl p-2 grid md:grid-cols-2 lg:grid-cols-2 items-stretch rounded-2xl shadow-lg mb-5"
                  // style={{
                  //   flex: "0 0 auto",
                  //   backgroundColor: "#ffff",
                  //   border: "1px solid black",
                  // }}
                  style={{
                    flex: "0 0 auto",
                    backgroundColor: "#ffff",
                    border: "1px solid black",
                    backgroundImage: `url(${blg})`, // Set the image as the background
                    backgroundSize: "cover", // Ensure the image covers the entire card
                    backgroundPosition: "center", // Center the image
                  }}
                >
                  {/* Left Column: Text Content */}
                  <div className="flex flex-col justify-between p-1">
                    <p
                      className="text-lg  md:text-lg lg:text-xl mb-4 text-left font-normal ml-4 lg:ml-5 lg:mt-5 md:ml-5 md:mt-5"
                      style={{
                        wordWrap: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {blog.content}
                    </p>
                    {/* Left Column: Images */}
                    <div className="flex">
                      <div className="flex justify-start items-center md:hidden lg:hidden">
                        <img
                          src={blog.image}
                          alt={blog.author}
                          className="w-24 h-24 ml-4 object-contain block sm:hidden"
                        />
                      </div>

                      <div>
                        <h3
                          className="flex justify-start ml-12 mt-5  md:justify-end lg:justify-end font-semibold text-lg text-left md:text-left lg:text-left md:text-base lg:text-base lg:ml-2 md:ml-4 lg:mr-20 md:mr-8 lg:mb-5 md:mb-5"
                          style={{ color: "#222222" }}
                        >
                          - {blog.author}, {blog.role}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end items-center">
                    <img
                      src={blog.image}
                      alt={blog.author}
                      className="w-64 h-64 hidden md:flex lg:flex"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

      </div>







      <div className="relative w-full flex justify-center sm-max:w-full xl:h-[400px] mt-20 mx-auto m-5 lg:h-42 md:h-42  sm:p-0">
        {/* Group1 Image for non-mobile screens */}
        <img
          src={Group1}
          alt="Group"
          className="hidden sm:block border-t-2 border-gray-900 lg:w-full md:w-full lg:h-auto md:h-auto sm:rounded-none h-[400px] object-cover"
        />

        {/* Group1 Alternative Image for mobile screens */}
        <img
          src={Group2}
          alt="Mobile Group"
          className="block sm:w-full sm:hidden lg:w-full md:w-full lg:h-auto md:h-auto rounded-lg h-[400px] object-cover"
        />


        {/* Hand GIF 1 */}
        <div className="absolute right-0 w-24 h-24 md:w-30 md:h-30 mb-32 mt-5 lg:mb-44 mr-28 mobile-m:mr-36 mobile-l:mr-40 lg:hidden md:hidden">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Hand GIF 2 */}
        <div className="absolute bottom-0 right-0 md:mr-5 md:mb-[65px] lg:mb-[60px] lg:w-52 lg:h-52 xl:mb-[65px] xl:h-72 xl:w-[350px] xl:mr-0 w-24 h-24 md:w-40 md:h-40 mb-32 mr-14 hidden md:flex">
          <img
            src={Hand}
            alt="Hand GIF"
            className="w-full h-full"
          />
        </div>

        {/* Vector624 Image */}
        <img
          src={Cloud}
          alt="Vector"
          className="absolute w-full h-auto bottom-0 md:-bottom-0.5 lg:-bottom-1.5 left-0 xl:h-44 h-24 md:h-28 lg:h-32 px-3 sm:p-0 hidden sm:block"
        />

        {/* Border below vector image */}
        <div className="absolute bottom-0 left-0 w-full sm:h-4 h-2 bg-black rounded-lg"></div>

        <div className="absolute inset-0 flex flex-col items-center sm:items-start sm:justify-center px-4 lg:py-10 md:px-12 mb-20">
          <h3 className="font-bold font-rubik text-xl mt-32 sm:mt-0 ml-2 sm:ml-0 lg:ml-20 md:ml-0 xl:text-4xl lg:text-4xl md:text-3xl text-white mb-2 sm:p-0 m-0 text-center sm:text-left">
            Are you commitment phobic?
          </h3>
          <p className="font-normal jakarta ml-0 sm:ml-10 mr-0 sm:mr-12 lg:ml-20 md:ml-0 lg:mt-0 md:mt-0 text-base md:text-xl text-white mb-4 text-center sm:text-left">
            Join our community for a situationship!
          </p>
          <div className="ml-0 sm:ml-24 md:ml-0 lg:ml-20 xl:ml-20">
            <button
              className="flex items-center bg-white px-4 py-2 md:px-2 md:py-2 rounded-lg border border-horizonOrange-950 hover:bg-gray-200"
              onClick={() => navigate("/contactus")}
            >
              <span className="text-black jakarta text-start text-xs font-semibold md:text-base lg:text-base w-20 md:w-auto lg:w-auto text-center sm:text-left">
                Get in touch
              </span>
              <img
                src={Icon1}
                alt="Icon"
                className="w-3 h-3 md:w-4 md:h-4 lg:w-5 lg:h-5 ml-2"
              />
            </button>
          </div>
        </div>
      </div>







      {/* Blogs Section */}
      <div
        className="text-center mt-12 mb-8 pb-5"
        style={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          overflow: "hidden",
          position: "relative",
        }}
      >
        <h3 className="rubik text-4xl font-bold leading-[48px] text-center" style={{ color: "#000000" }}>
          Blogs
        </h3>
        <h3 className="mt-2 jakarta" style={{ color: "#222222" }}>
          Your go-to guide for cloud savings and insights
        </h3>
        <div
          className="blogs-container flex mt-8 animate-scroll"
          style={{
            display: "inline-flex",
            whiteSpace: "nowrap",
            boxSizing: "border-box",
          }}
        >
          {blogs.concat(blogs).map((blog, index) => (
            <div
              key={index}
              className="bg-white rounded-2xl border border-black mx-4 blog-card"
              style={{
                width: "16rem",
                height: "auto",
                overflow: "hidden",
                display: "inline-block",
                boxSizing: "border-box",
              }}
            >
              <img
                src={blog.image}
                alt={blog.title}
                className="rounded-t-2xl object-cover"
                style={{
                  width: "100%",
                  height: "15rem",
                  objectFit: "cover",
                }}
              />
              <div
                className="pt-5 pl-5 pr-5 pb-4 text-left"
                style={{
                  overflow: "visible",
                  boxSizing: "border-box",
                }}
              >
                <p className="text-gray-500 text-sm jakarta">{blog.date}</p>
                <h3
                  className="mt-1 text-sm jakarta"
                  style={{
                    color: "#000000",
                    whiteSpace: "normal",
                    lineHeight: "1.5",
                    wordBreak: "break-word",
                  }}
                >
                  {blog.title}
                </h3>
                <div className="flex flex-wrap gap-2 mt-2">
                  {blog.tags.map((tag, tagIndex) => (
                    <span
                      key={tagIndex}
                      className="px-2 py-2 text-xs rounded jakarta"
                      style={{
                        backgroundColor: "#E1F5FC",
                        color: "#222222",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

      </div>







      <div className="py-6 px-2 bg-white">
        <h2 className="rubik text-4xl font-bold leading-[48px] text-center mb-12">
          Frequently Asked Questions
        </h2>
        <div className="max-w-4xl mx-auto space-y-4 group border " style={{ border: "1px solid black", borderRadius: "25px", padding: "20px" }}>
          {/* FAQ 1 */}
          <div>
            {faqs.map((faq, index) => (
              <div key={index} className="border-b py-4">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleFaq(index)}
                >
                  <h2 className="font-semibold jakarta">{faq.question}</h2>
                  <span>
                    {openIndex === index ? (
                      <img src={xmark} alt="Close" className="w-5 h-5" />
                    ) : (
                      <img src={plus} alt="Open" className="w-5 h-5" />
                    )}
                  </span>

                </div>
                {openIndex === index && <p className="mt-2 jakarta">{faq.answer}</p>}
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
